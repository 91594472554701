// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState, TBaseProps } from '../../types';
import { FundEditTemplate } from '../../templates/Funds';
import { saveFundFormAction, requestFundAction } from '../../../store/actions';

type TProps = TBaseProps & {};

export class FundEdit extends React.Component<TProps, {}> {
  constructor(props) {
    super(props);
    const fundId = parseInt(props.match.params.id, 10); 
    props.requestFundAction(fundId);
  }

  render() {
    return <FundEditTemplate {...this.props} />;
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  fund: state.fund.fund,
  loading: state.fund.loading
});
export const FundEditPage = connect(mapStateToProps, {
  saveFundFormAction,
  requestFundAction,
})(FundEdit);