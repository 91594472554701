import * as React from 'react';
import moment from 'moment';
import { Form, Input, InputNumber, Button, DatePicker } from 'antd';
import { formItemLayout } from './FormElements';

const { TextArea } = Input;
const FormItem = Form.Item;

class Shareholder extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = props.shareholder;
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form
        className="shareholder-form"
        onSubmit={e => {
          e.preventDefault();
          this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
              if (this.props.shareholder && this.props.shareholder.user_id) {
                this.props.saveShareholderFormAction({
                  user_id: this.props.shareholder.user_id,
                  fund_id: this.props.shareholder.fund_id,
                  ...values,
                });
              } else {
                this.props.saveShareholderFormAction({
                  fund_id: parseInt(this.props.match.params.id),
                  ...values,
                });
              }
            }
          });
        }}
      >
        <FormItem {...formItemLayout} label="First Name">
          {getFieldDecorator('first_name', {
            initialValue: this.state.first_name,
            placeholder: "First Name",
            rules: [{
              required: true, whitespace: true, message: "Please provide the first name" 
            }],
          })(
            <Input />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Last Name">
          {getFieldDecorator('last_name', {
            initialValue: this.state.last_name,
            rules: [{
              required: true, whitespace: true, message: "Please provide the last name" 
            }],
          })(
            <Input />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Email">
          {getFieldDecorator('email', {
            initialValue: this.state.email,
            rules: [{
              type: 'email', message: 'This is not a valid email address.',
            }, {
              required: true, message: 'Please provide an email address.',
            }],
          })(
            <Input />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Date">
          {getFieldDecorator('date', {
            initialValue: this.state.date ? moment(this.state.date).utc(true) : moment().utc(true),
            rules: [{ required: true }]
          })(
            <DatePicker
              format="DD/MM/YYYY"
              allowClear={false}
              disabledDate={(currentDate) => currentDate.isAfter()}
            />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Initial Score">
          {getFieldDecorator('initial_score', {
            initialValue: this.state.initial_score,
            rules: [{
              required: true, type: "number", min: 0, message: "Please provide a valid score",
            }],
          })(
            <InputNumber />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Score">
          {getFieldDecorator('score', {
            initialValue: this.state.score,
            rules: [{
              required: true, type: "number", min: 0, message: "Please provide a valid score",
            }],
          })(
            <InputNumber />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Investment">
          {getFieldDecorator('capital', {
            initialValue: this.state.capital,
            rules: [{
              required: true, type: "number", min: 0, message: "Please provide a valid number",
            }],
          })(
            <InputNumber />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Notes">
          {getFieldDecorator('notes', {
            initialValue: this.state.notes,
          })(
            <TextArea />
          )}
        </FormItem>
        {this.props.validation && <FormItem className="validation-message">{this.props.validation}</FormItem>}
        <FormItem {...formItemLayout}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </FormItem>
      </Form>
    );
  }
}
export const ShareholderForm = Form.create()(Shareholder);