// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { SITE_PATHS, USER_ROLES } from '../../../config/api.constants';
import { logoutAction } from '../../../store/actions';

const Private = ({ component: Component, user, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (user && (user.role === USER_ROLES.USER || user.role === USER_ROLES.ADMIN)
        ? <Component {...rest} {...props} user={user} />
        : <Redirect to={SITE_PATHS.LOGIN} />)
    }
  />
);

const mapStateToProps = (state: TGlobalState) => ({
  user: state.user.profile,
});
export const UserRoute = connect(mapStateToProps, {
  logoutAction,
})(Private);

