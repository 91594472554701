
export const requestEmailsAction = (payload) : TAction => ({
  type: 'LOGS_ACTIONS.REQUEST_EMAILS',
  payload,
});

export const receivedEmailsAction = (payload) : TAction => ({
  type: 'LOGS_ACTIONS.RECEIVED_EMAILS',
  payload,
});

export const requestLogsAction = (payload) : TAction => ({
  type: 'LOGS_ACTIONS.REQUEST_LOGS',
  payload,
});

export const receivedLogsAction = (payload) : TAction => ({
  type: 'LOGS_ACTIONS.RECEIVED_LOGS',
  payload,
});