// @flow
import * as React from 'react';
import _ from 'lodash';
import { Table } from 'antd';
import { Template } from '../Template';
import { Loader } from '../../atoms/Loader';
import { numberWithCommas } from '../../../utils';
import "./inventory.css";

export const InventoryTemplate = (props) => {
  const data = props.inventory;
  const assetFilter = _.uniq(props.inventory.map(asset => asset.asset))
    .filter(asset => asset && asset !== 'ZEUR' && asset !== 'XBt')
    .sort()
    .map(asset => ({ text: asset.toUpperCase(), value: asset }));

  const exchangeFilter = [
    { text: 'Binance', value: 1 },
    { text: 'Kraken', value: 4 },
  ];
  const totalRow = { asset: 'Total', amount: '0', type: 'total', 'valueBTC': '0', 'valueEUR': '0' };
  if (props.inventory.length > 0) {
    const totalBTC = props.inventory.map(asset => parseFloat(asset.valueBTC)|| 0).reduce((p, c) => p + c);
    const totalEUR = props.inventory.map(asset => parseFloat(asset.valueEUR)|| 0).reduce((p, c) => p + c);
    totalRow.valueBTC = totalBTC.toString();
    totalRow.valueEUR = totalEUR.toString();
  }
  const inventoryColumns = [{
    title: 'Asset',
    dataIndex: 'asset',
    key: 'asset',
    filters: assetFilter,
    onFilter: (value, record) => {
      if (value === 'BTC') {
        return record.asset.indexOf(value) === 0 || record.asset.indexOf('XBt') === 0;
      } else if (value === 'EUR') {
        return record.asset.indexOf(value) === 0 || record.asset.indexOf('ZEUR') === 0;
      } else {
        return record.asset.indexOf(value) === 0;
      }
    },
    sorter: (a, b) => ('' + a.asset).localeCompare(b.asset),
    sortDirections: ['descend'],
  }, {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  }, {
    title: 'Source',
    dataIndex: 'type',
    key: 'type',
    filter: exchangeFilter,
    onFilter: (value, record) => record.btc_value_src === value,
    sorter: (a, b) => a.btc_value_src && b.btc_value_src && a.btc_value_src - b.btc_value_src,
    sortDirections: ['descend'],
  }, {
    title: 'BTC Value',
    dataIndex: 'valueBTC',
    key: 'valueBTC',
    render: (value) =>  value && numberWithCommas(parseFloat(value).toFixed(8)),
    filters: [
      { text: 'Hide small balances', value: 0.0001 }
    ],
    sorter: (a, b) => a.valueBTC - b.valueBTC,
    onFilter: (value, record) => record.valueBTC > value,
  }, {
    title: 'EUR Value',
    dataIndex: 'valueEUR',
    key: 'valueEUR',
    sorter: (a, b) => a.valueEUR - b.valueEUR,
    render: (value) =>  value && numberWithCommas(parseFloat(value).toFixed(2))
  }];
  
  return (
    <Template menuItem="funds" {...props}>
      <h1>Inventory</h1>
      <Loader loading={props.inventoryLoading}>
        <Table
          dataSource={data}
          columns={inventoryColumns}
          pagination={false}
          rowClassName={(record) => record.valueBTC ? "" : "error"}
          size="middle"
          rowKey="id"
          footer={() => props.inventory.length > 0 && (
            <div className="text-right">
              <p>Total Euro: {numberWithCommas(parseFloat(totalRow.valueEUR).toFixed(2))}</p>
              <p>Total BTC: {numberWithCommas(parseFloat(totalRow.valueBTC).toFixed(8))}</p>
            </div>
          )}

        />
      </Loader>
      <div className="space-25" />
    </Template>
  )};