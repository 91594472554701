// @flow
import { TBAseAction, TColdStorage, TExchange } from '../../types';

export const requestExchangesAction = (fundId: number): TBaseAction<> => ({
  payload: fundId,
  type: 'FUND_ACTIONS.REQUEST_EXCHANGES'
});

export const requestColdStorageAction = (fundId: number): TBaseAction<> => ({
  payload: fundId,
  type: 'FUND_ACTIONS.REQUEST_COLD_STORAGES'
});

export const receivedExchangesAction = (exchanges): TBaseAction<TExchange[]> => ({
  payload: exchanges,
  type: 'FUND_ACTIONS.RECEIVED_EXCHANGES'
});

export const receivedColdStorageAction = (storages: TColdStorage[]): TBaseAction<> => ({
  payload: storages,
  type: 'FUND_ACTIONS.RECEIVED_COLD_STORAGES'
});

export const saveExchangeFormAction = (exchange: TExchange): TBAseAction<TShareholder> => ({
  payload: exchange,
  type: 'FUND_ACTIONS.SAVE_EXCHANGE_FORM',
});

export const removeExchangeAction = (exchange: TExchange): TBAseAction<TShareholder> => ({
  payload: exchange,
  type: 'FUND_ACTIONS.REMOVE_EXCHANGE',
});

export const saveColdStorageFormAction = (storage: TStorage): TBAseAction<TShareholder> => ({
  payload: storage,
  type: 'FUND_ACTIONS.SAVE_COLD_STORAGE_FORM',
});

export const removeColdStorageAction = (storage: TStorage): TBAseAction<TShareholder> => ({
  payload: storage,
  type: 'FUND_ACTIONS.REMOVE_COLD_STORAGE',
});