// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState, TBaseProps } from '../../types';
import { PostCreateTemplate } from '../../templates/Posts';
import { savePostFormAction } from '../../../store/actions';

type TProps = TBaseProps & {};

export const PostCreate = (props: TProps) => <PostCreateTemplate {...props} />

const mapStateToProps = (state: TGlobalState, ownProps) => ({
  post: {
    user_id: state.user.profile.id, 
    title: '',
    fund_id: parseInt(ownProps.match.params.id, 10),
    body: `
      <p>Hey this <strong>editor</strong> rocks 😀</p>
      <p>${state.user.profile.first_name} ${state.user.profile.last_name}</p>
      `,
  },
});
export const PostCreatePage = connect(mapStateToProps, {
  savePostFormAction,
})(PostCreate);