// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState, TBaseProps } from '../../types';
import { AssetsTemplate } from '../../templates/Assets';
import { requestExchangesAction, requestColdStorageAction, removeExchangeAction, removeColdStorageAction } from '../../../store/actions';

type TProps = TBaseProps & {};

class Assets extends React.Component<TProps, {}> {
  constructor(props) {
    super(props);
    const fundId = parseInt(props.match.params.id, 10); 
    props.requestColdStorageAction(fundId);
    props.requestExchangesAction(fundId);
  }
  render() {
    return <AssetsTemplate {...this.props}/>
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  fund: state.fund.fund,
  exchanges: state.fund.exchangeList,
  exchangesLoading: state.fund.exchangeListLoading,
  storages: state.fund.coldStorageList,
  storagesLoading: state.fund.coldStorageListLoading,
});
export const AssetsPage = connect(mapStateToProps, {
  requestExchangesAction,
  requestColdStorageAction,
  removeExchangeAction,
  removeColdStorageAction,
})(Assets);