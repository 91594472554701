// @flow
import * as React from 'react';
import { Template } from '../Template';
import { FundForm } from '../../atoms/Forms';

export const FundCreateTemplate = (props) => (
  <Template menuItem="funds" {...props}>
    <h1>Add new Fund</h1>
    <FundForm {...props} />
  </Template>
);