// @flow
import { put, call } from 'redux-saga/effects';

import {
  createHandleErrorAction,
  createHandleServerErrorAction,
  createHandleNetworkErrorAction,
  createHandleOtherErrorAction,
  logoutAction,
} from '../store/actions';

export function* handleError(err: any): Generator<any, any, any> {
  yield put(createHandleErrorAction(err));

  if (err.response) {
    if (err.response.status === 403) {
      alert(`Uw sessie is verlopen, gelieve opnieuw in te loggen.`);
      yield put(logoutAction());
    } else {
      yield put(createHandleServerErrorAction(err));
    }
  } else if (err.message === 'Network Error' || err.code === 'ECONNABORTED') {
    yield put(createHandleNetworkErrorAction(err));
  } else {
    yield put(createHandleOtherErrorAction(err));
  }
}

export const genericErrorHandler = (saga: Function, ...args: any) =>
  function* handleApp(action: Object): Generator<any, any, any> {
    try {
      yield call(saga, ...args, action);
    } catch (err) {
      yield call(handleError, ...args, err);
    }
  };
