// @flow
import { appApi } from '../config/api.config';
import { API_PATHS, BASE_URL } from '../config/api.constants';
import type { TExchange, TColdStorage, TShareholder, TShareholderKey, TEmail } from '../types';
import { createPath } from '../utils';

// FUND
export const getFunds = () => appApi.get(BASE_URL + API_PATHS.ADMIN);
export const getFundAdmin = (fundId: number) => appApi.get(createPath(BASE_URL + API_PATHS.ADMIN + API_PATHS.FUND, { fundId }));
export const getFund = (fundId: number) => appApi.get(createPath(BASE_URL + API_PATHS.CHARTS, { fundId }));
export const createFund = (fund: TFund) => appApi.post(BASE_URL + API_PATHS.ADMIN, fund);
export const updateFund = (fund: TFund) => appApi.put(createPath(BASE_URL + API_PATHS.ADMIN + API_PATHS.FUND, { fundId: fund.id }), fund);
export const removeFund = (fund: TFund) => appApi.delete(createPath(BASE_URL + API_PATHS.ADMIN + API_PATHS.FUND, { fundId: fund.id }));

export const getSymbols = () => appApi.get(BASE_URL + API_PATHS.SYMBOLS);

// EXCHANGES
export const getExchanges = (fundId: number) => appApi.get(createPath(BASE_URL + API_PATHS.ADMIN + API_PATHS.FUND + API_PATHS.EXCHANGES, { fundId }));
export const createExchange = (exchange: TExchange) => appApi.post(
  createPath(BASE_URL + API_PATHS.ADMIN + API_PATHS.FUND + API_PATHS.EXCHANGES, { fundId: exchange.fund_id }), exchange);
export const updateExchange = (exchange: TExchange) => appApi.put(createPath(BASE_URL + API_PATHS.ADMIN + API_PATHS.FUND + API_PATHS.EXCHANGES + API_PATHS.EXCHANGE, { fundId: exchange.fund_id, exchangeId: exchange.id }), exchange);
export const removeExchange = (exchange: TExchange) => appApi.delete(createPath(BASE_URL + API_PATHS.ADMIN + API_PATHS.FUND + API_PATHS.EXCHANGES + API_PATHS.EXCHANGE, { fundId: exchange.fund_id, exchangeId: exchange.id }));

// COLD STORAGE
export const getColdStorages = (fundId: number) => appApi.get(createPath(BASE_URL + API_PATHS.ADMIN + API_PATHS.FUND + API_PATHS.STORAGES, { fundId }));
export const createColdStorage = (storage: TColdStorage) => appApi.post(
  createPath(BASE_URL + API_PATHS.ADMIN + API_PATHS.FUND + API_PATHS.STORAGES, { fundId: storage.fund_id }), storage);
export const updateColdStorage = (storage: TColdStorage) => appApi.put(createPath(BASE_URL + API_PATHS.ADMIN + API_PATHS.FUND + API_PATHS.STORAGES + API_PATHS.STORAGE, { fundId: storage.fund_id, storageId: storage.id }), storage);
export const removeColdStorage = (storage: TColdStorage) => appApi.delete(createPath(BASE_URL + API_PATHS.ADMIN + API_PATHS.FUND + API_PATHS.STORAGES + API_PATHS.STORAGE, { fundId: storage.fund_id, storageId: storage.id }));

// INVENTORY
export const getInventory = (fundId: number) => appApi.get(createPath(BASE_URL + API_PATHS.ADMIN + API_PATHS.FUND + API_PATHS.INVENTORY, { fundId }));

// SHAREHOLDERS
export const getShareholders = (fundId: number) => appApi.get(createPath(BASE_URL + API_PATHS.ADMIN + API_PATHS.FUND + API_PATHS.SHAREHOLDERS, { fundId }));
export const createShareholder = (shareholder: TShareholder) => appApi.post(
  createPath(BASE_URL + API_PATHS.ADMIN + API_PATHS.FUND + API_PATHS.SHAREHOLDERS, { fundId: shareholder.fund_id }), shareholder);
export const updateShareholder = (shareholder: TShareholder) => appApi.put(createPath(BASE_URL + API_PATHS.ADMIN + API_PATHS.FUND + API_PATHS.SHAREHOLDERS + API_PATHS.SHAREHOLDER, { fundId: shareholder.fund_id, shareholderId: shareholder.user_id }), shareholder);
export const removeShareholder = (shareholder: TShareholderKey) => appApi.delete(createPath(BASE_URL + API_PATHS.ADMIN + API_PATHS.FUND + API_PATHS.SHAREHOLDERS + API_PATHS.SHAREHOLDER, { fundId: shareholder.fund_id, shareholderId: shareholder.user_id }));
export const sendShareholderEmail = (email: TEmail) => appApi.post(createPath(BASE_URL + API_PATHS.ADMIN + API_PATHS.FUND + API_PATHS.SHAREHOLDERS + API_PATHS.EMAIL, { fundId: email.fund_id }), email);