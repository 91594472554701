// @flow
import * as React from 'react';
import { Template } from '../Template';
import { ColdStorageForm } from '../../atoms/Forms';

export const ColdStorageCreateTemplate = (props) => (
  <Template menuItem="funds" {...props}>
    <h1>Add Cold Storage</h1>
    <div className="well">
      If multiple assets are on the same address, please add the address for every asset. The amount stored on the address should be updated manually.
    </div>
    <div className="space-25" />
    <ColdStorageForm {...props} />
  </Template>
  );