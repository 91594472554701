// @flow
import { combineReducers } from 'redux';
import { listReducer, loaderReducer, valueReducer } from './generic';

const reducer = combineReducers({
  indexData: listReducer('CHART_ACTIONS.RECEIVED_INDEX'),
  indexLoading: loaderReducer('CHART_ACTIONS.REQUEST_INDEX', 'CHART_ACTIONS.RECEIVED_INDEX'),
  assetsData: listReducer('CHART_ACTIONS.RECEIVED_ASSETS'),
  assetsLoading: loaderReducer('CHART_ACTIONS.REQUEST_ASSETS', 'CHART_ACTIONS.RECEIVED_ASSETS'),
  type: valueReducer('', 'CHART_ACTIONS.RECEIVED_FUND_INFO_TYPE'),
});

export default reducer;
