// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState, TBaseProps } from '../../types';
import { FundsTemplate } from '../../templates/Funds';
import { requestFundsAction, removeFundAction } from '../../../store/actions';

type TProps = TBaseProps & {};

class Funds extends React.Component<TProps, {}> {
  constructor(props) {
    super(props);
    props.requestFundsAction();
  }
  render() {
    return <FundsTemplate {...this.props}/>
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  funds: state.fund.fundList,
  loading: state.fund.fundListLoading,
});
export const FundsPage = connect(mapStateToProps, {
  requestFundsAction,
  removeFundAction,
})(Funds);