// @flow
import { combineReducers } from 'redux';
import { listReducer, objectReducer, loaderReducer, valueReducer } from './generic';

const reducer = combineReducers({
  fund: objectReducer('FUND_ACTIONS.RECEIVED_FUND'),
  info: objectReducer('FUND_ACTIONS.RECEIVED_FUND_INFO'),
  loading: loaderReducer('FUND_ACTIONS.REQUEST_FUND', 'FUND_ACTIONS.RECEIVED_FUND'),
  
  fundList: listReducer('FUND_ACTIONS.RECEIVED_FUNDS'),
  fundListLoading: loaderReducer('FUND_ACTIONS.REQUEST_FUNDS', 'FUND_ACTIONS.RECEIVED_FUNDS'),
  
  exchangeList: listReducer('FUND_ACTIONS.RECEIVED_EXCHANGES'),
  exchangeListLoading: loaderReducer('FUND_ACTIONS.REQUEST_EXCHANGES', 'FUND_ACTIONS.RECEIVED_EXCHANGES'),
  
  coldStorageList: listReducer('FUND_ACTIONS.RECEIVED_COLD_STORAGES'),
  coldStorageListLoading: loaderReducer('FUND_ACTIONS.REQUEST_COLD_STORAGES', 'FUND_ACTIONS.RECEIVED_COLD_STORAGES'),
  
  shareholderList: listReducer('FUND_ACTIONS.RECEIVED_SHAREHOLDERS'),
  shareholderListLoading: loaderReducer('FUND_ACTIONS.REQUEST_SHAREHOLDERS', 'FUND_ACTIONS.RECEIVED_SHAREHOLDERS'),
  shareholderValidation: valueReducer(null, 'FUND_ACTIONS.RECEIVED_SHAREHOLDER_VALIDATION'),

  inventory: listReducer('FUND_ACTIONS.RECEIVED_INVENTORY'),
  inventoryLoading: loaderReducer('FUND_ACTIONS.REQUEST_INVENTORY', 'FUND_ACTIONS.RECEIVED_INVENTORY'),
});

export default reducer;
