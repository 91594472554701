// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState, TBaseProps } from '../../../types';
import { ForgotPasswordTemplate } from '../../templates';
import { requestForgotPasswordAction } from '../../../store/actions';

class ForgotPassword extends React.Component<TBaseProps, {}> {
  render() {
    return <ForgotPasswordTemplate {...this.props} />;
  }
}

const mapStateToProps = (state: TGlobalState, ownProps: TProps) => ({
  validation: state.user.validation,
});
export const ForgotPasswordPage = connect(mapStateToProps, {
  requestForgotPasswordAction,
})(ForgotPassword);
