// @flow
import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Loader } from '../Loader';

const options = {
  chart: {
    backgroundColor:'rgba(255, 255, 255, 0.0)',
  },
  title: {
    text: 'Current Assets'
  },
  subtitle: {
    text: 'Portfolio Assets'
  },
  colors: ['#1890ff', '#722ed1', '#13c2c2', '#52c41a', '#eb2f96', '#f5222d', '#fa8c16', '#fadb14', '#fa541c', '#2f54eb', '#a0d911', '#faad14'],
  credits: {
    enabled: false,
  },
  series: [{
    name: 'Asset',
    type: 'pie'
  }]
};  

type TProps = {
  assets: TAsset[],
  loading: boolean,
}

export class FundAssetChart extends React.Component<TProps, {}> {
  render() {
    options.series[0].data = this.props.assets.filter((v, i) => v[1] > 0.003);
    options.tooltip = {
      formatter: function() {
        return `${this.key}: ${this.y.toFixed(2)}%`
      }
    };
    return (
      <Loader loading={this.props.loading} text="Live data wordt opgehaald">
        {
          options.series[0].data.length > 0 &&
          (
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
            />
          )
        }
      </Loader>
    )
  }
}