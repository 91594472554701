// @flow
import * as React from 'react';
import { Template } from '../Template';
import { EmailForm } from '../../atoms/Forms';

export const EmailCreateTemplate = (props) => (
  <Template menuItem="funds" {...props}>
    <h1>Send email</h1>
    <EmailForm {...props} />
  </Template>
);