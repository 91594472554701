// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState, TBaseProps } from '../../types';
import { ShareholderCreateTemplate } from '../../templates/Shareholders';
import { saveShareholderFormAction } from '../../../store/actions';

type TProps = TBaseProps & {};

const ShareholderCreate = (props: TProps) => <ShareholderCreateTemplate {...props} />;

const mapStateToProps = (state: TGlobalState, props: TProps) => ({
  shareholder: {
    first_name: '',
    last_name: '',
    email: '',
    score: 1,
    initial_score: 1,
    capital: 0,
  },
  validation: state.fund.shareholderValidation,
});
export const ShareholderCreatePage = connect(mapStateToProps, {
  saveShareholderFormAction,
})(ShareholderCreate);