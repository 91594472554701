// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState, TBaseProps } from '../../types';
import { InventoryTemplate } from '../../templates/Inventory';
import { requestInventoryAction } from '../../../store/actions';

type TProps = TBaseProps & {};

class Inventory extends React.Component<TProps, {}> {
  constructor(props) {
    super(props);
    const fundId = parseInt(props.match.params.id, 10); 
    props.requestInventoryAction(fundId);
  }
  render() {
    return <InventoryTemplate {...this.props}/>
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  fund: state.fund.fund,
  inventory: state.fund.inventory,
  inventoryLoading: state.fund.inventoryLoading,
});
export const InventoryPage = connect(mapStateToProps, {
  requestInventoryAction,
})(Inventory);