// @flow
import { combineReducers } from 'redux';
import user from './user.reducer';
import fund from './fund.reducer';
import charts from './charts.reducer';
import logs from './logs.reducer';
import posts from './posts.reducer';

const reducer = combineReducers({
  user,
  fund,
  charts,
  logs,
  posts,
});

export default reducer;
