// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState, TBaseProps } from '../../types';
import { ShareholderEditTemplate } from '../../templates/Shareholders';
import { saveShareholderFormAction } from '../../../store/actions';

type TProps = TBaseProps & {};

const ShareholderEdit = (props: TProps) => <ShareholderEditTemplate {...props} />;

const mapStateToProps = (state: TGlobalState, props: TProps) => ({
  shareholder: state.fund.shareholderList
    .find(shareholder => shareholder.user_id.toString() === props.match.params.shareholderId),
  loading: state.fund.loading,
  validation: state.fund.shareholderValidation,
});

export const ShareholderEditPage = connect(mapStateToProps, {
  saveShareholderFormAction,
})(ShareholderEdit);