// @flow
import type { TUser } from '../types';
import { appApi } from '../config/api.config';
import { API_PATHS, BASE_URL } from '../config/api.constants';
import { createPath } from '../utils';

export const login = (user: TUser) => appApi
.post(BASE_URL + API_PATHS.USER + API_PATHS.LOGIN, user);

export const getUser = (userId: string) => appApi
.get(BASE_URL + API_PATHS.USER, { id: userId })

export const getUserByCode = (code: string) => appApi
.get(createPath(BASE_URL + API_PATHS.USER + API_PATHS.CODE, { code }))

export const activateUser = (code: string, password: string) => appApi
  .post(createPath(BASE_URL + API_PATHS.USER + API_PATHS.ACTIVATE, { code } ), { password })

export const inviteUser = (fundId: number, userId: number) => appApi
  .post(createPath(BASE_URL + API_PATHS.ADMIN + API_PATHS.FUND + API_PATHS.INVITE, {fundId, userId}))

export const getShareholders = (fundId: number) => appApi
  .get(createPath(BASE_URL + API_PATHS.ADMIN + API_PATHS.FUND + API_PATHS.SHAREHOLDERS, { fundId }));

export const forgotPassword = (email: string) => appApi
  .post(BASE_URL + API_PATHS.USER + API_PATHS.FORGOT, { email });

export const resetPassword = (code: string, password: string) => appApi
  .post(createPath(BASE_URL + API_PATHS.USER + API_PATHS.RESET, { code }), { password })