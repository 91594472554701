// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState, TBaseProps } from '../../types';
import { EmailsTemplate } from '../../templates/Emails';
import { requestEmailsAction } from '../../../store/actions';

type TProps = TBaseProps & {};

class Emails extends React.Component<TProps, {}> {
  constructor(props) {
    super(props);
    const fundId = parseInt(props.match.params.id, 10); 
    props.requestEmailsAction({ fund_id: fundId });
  }
  render() {
    return <EmailsTemplate {...this.props}/>
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  emails: state.logs.emailList,
  loading: state.logs.emailListLoading,
});
export const EmailsPage = connect(mapStateToProps, {
  requestEmailsAction,
})(Emails);