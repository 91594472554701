// @flow
import * as React from 'react';
import { Template } from '../Template';
import { PostForm } from '../../atoms/Forms';

export const PostCreateTemplate = (props) => (
  <Template menuItem="funds" {...props}>
    <h1>Add new Post</h1>
    <PostForm {...props} />
  </Template>
);