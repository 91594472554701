// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState, TBaseProps } from '../../types';
import { ShareholdersTemplate } from '../../templates/Shareholders';
import { requestShareholdersAction, removeShareholderAction, sendInviteMailAction } from '../../../store/actions';

type TProps = TBaseProps & {};

class Shareholders extends React.Component<TProps, {}> {
  constructor(props: TProps) {
    super(props);
    const fundId = parseInt(props.match.params.id, 10);
    props.requestShareholdersAction(fundId);
  }
  render() {
    return <ShareholdersTemplate {...this.props}/>;
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  shareholders: state.fund.shareholderList,
  loading: state.fund.shareholderListLoading,
});
export const ShareholdersPage = connect(mapStateToProps, {
  requestShareholdersAction,
  removeShareholderAction,
  sendInviteMailAction,
})(Shareholders);