import * as React from 'react';
import { Form, Select, Input, Button } from 'antd';
import { formItemLayout } from './FormElements';

const FormItem = Form.Item;
const Option = Select.Option;

class Exchange extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = {
      api_key: props.exchange.api_key,
      api_secret: props.exchange.api_secret,
      type: props.exchange.type,
    };
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form
        className="exchange-form"
        onSubmit={e => {
          e.preventDefault();
          this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
              if (this.props.exchange) {
                this.props.saveExchangeFormAction({
                  id: this.props.exchange.id,
                  fund_id: parseInt(this.props.match.params.id),
                  ...values,
                });
              } else {
                this.props.saveExchangeFormAction({
                  fund_id: parseInt(this.props.match.params.id),
                  ...values,
                });
              }
            }
          });
        }}
      >
        <FormItem {...formItemLayout}  label="Exchange">
          {getFieldDecorator('type', {
            initialValue: this.state.type,
            value: this.state.type,
            rules: [{
              required: true, message: "Please select an exchange"
            }],
          })(
            <Select style={{ minWidth: '120px' }}>
              <Option value="BINANCE">Binance</Option>
              <Option value="KRAKEN">Kraken</Option>
              <Option value="POLONIEX">Poloniex</Option>
              <Option value="BITTREX">Bittrex</Option>
              <Option value="BITMEX">Bitmex</Option>
            </Select>,
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="API Key">
          {getFieldDecorator('api_key', {
            initialValue: this.state.api_key,
            rules: [{
              required: true, message: "Please provide an API Key or Public Key"
            }]
          })(
            <Input />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Private Key">
          {getFieldDecorator('api_secret', {
            initialValue: this.state.api_secret,
            rules: [{
              required: true, message: "Please provide an API Secret or Private Key"
            }]
          })(
            <Input />
          )}
        </FormItem>
        <FormItem {...formItemLayout}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </FormItem>
      </Form>
    );
  }
}
export const ExchangeForm = Form.create()(Exchange);