// @flow
import * as React from 'react';
import { Table, Button, Icon, Menu, Dropdown } from 'antd';
import { createPath } from '../../../utils';
import { Template } from '../Template';
import { SITE_PATHS } from '../../../config/api.constants';
import { browserHistory } from '../../../store';
import { Loader } from '../../atoms/Loader';


const menu = (props, row) => (
  <Menu>
    
    <Menu.Item key="2" onClick={() => browserHistory.push(createPath(SITE_PATHS.FUND+SITE_PATHS.SHAREHOLDERS, { id: row.id }))}>
      <Icon type="team" theme="outlined" /> Shareholders
    </Menu.Item>
    <Menu.Item key="3" onClick={() => browserHistory.push(createPath(SITE_PATHS.FUND+SITE_PATHS.ASSETS, { id: row.id }))}>
      <Icon type="bank" theme="outlined" /> Assets
    </Menu.Item>
    <Menu.Item key="4" onClick={() => browserHistory.push(createPath(SITE_PATHS.FUND+SITE_PATHS.INVENTORY, { id: row.id }))}>
      <Icon type="bank" theme="outlined" /> Inventory
    </Menu.Item>
    <Menu.Divider />
    
    <Menu.Item key="5" onClick={() => browserHistory.push(createPath(SITE_PATHS.FUND+SITE_PATHS.POSTS+SITE_PATHS.NEW, { id: row.id }))}>
      <Icon type="form" theme="outlined" /> New Post
    </Menu.Item>
    <Menu.Item key="6" onClick={() => browserHistory.push(createPath(SITE_PATHS.FUND+SITE_PATHS.POSTS, { id: row.id }))}>
      <Icon type="form" theme="outlined" /> Posts
    </Menu.Item>
    <Menu.Divider />

    <Menu.Item key="9" onClick={() => browserHistory.push(createPath(SITE_PATHS.FUND+SITE_PATHS.EMAILS+SITE_PATHS.NEW, { id: row.id }))}>
      <Icon type="form" theme="outlined" /> New Email
    </Menu.Item>
    <Menu.Item key="1" onClick={() => browserHistory.push(createPath(SITE_PATHS.FUND+SITE_PATHS.EMAILS, { id: row.id }))}>
      <Icon type="mail" theme="outlined" /> Emails
    </Menu.Item>
    <Menu.Divider />

    <Menu.Item key="0" onClick={() => browserHistory.push(createPath(SITE_PATHS.FUND+SITE_PATHS.LOGS, { id: row.id }))}>
      <Icon type="exception" theme="outlined" /> Logs
    </Menu.Item>
    <Menu.Item key="7" onClick={() => browserHistory.push(createPath(SITE_PATHS.FUND+SITE_PATHS.EDIT, { id: row.id }))}>
      <Icon type="edit" theme="outlined" /> Edit Fund
    </Menu.Item>
    <Menu.Item key="8" onClick={() => window.confirm("Are you sure you want to delete this fund and all its assets?") && props.removeFundAction(row)}>
      <Icon type="delete" theme="outlined" /> Delete
    </Menu.Item>
  </Menu>
);

export const FundsTemplate = (props) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '',
      render: (row) => (
        <div className="text-right">
          <Button
            onClick={() => browserHistory.push(createPath(SITE_PATHS.FUND, { id: row.id }))}>
            <Icon type="pie-chart" theme="outlined" /> <span className="hide-mobile">Fund</span>
          </Button>
          
          <Dropdown overlay={menu(props, row)} trigger={['click']}>
            <Button className="ant-dropdown-link" href="#">
              <Icon type="setting" theme="outlined" />
            </Button>
          </Dropdown>
        </div>
      ) 
    }
  ];

  return (
  <Template menuItem="funds" {...props}>
    <h1>Funds</h1>
    <Loader loading={props.loading}>
      <Table
        dataSource={props.funds}
        columns={columns}
        size="middle"
        rowKey="id"
      />
    </Loader>
    <Button type="primary" onClick={() => browserHistory.push(SITE_PATHS.FUNDS+SITE_PATHS.NEW)}>Add new fund <Icon type="plus"/></Button>
  </Template>
  )};