// @flow
import * as React from 'react';
import { Table, Button, Icon, Input } from 'antd';
import { createPath } from '../../../utils';
import { Template } from '../Template';
import { SITE_PATHS } from '../../../config/api.constants';
import { browserHistory } from '../../../store';
import { Loader } from '../../atoms/Loader';

// const alphabet = Array.from({ length: 26 }, (_, i) => String.fromCharCode('a'.charCodeAt(0) + i));

export const ShareholdersTemplate = (props) => {

  const getNameSearchProps = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search shareholder`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={confirm}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={confirm}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record.first_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
      || record.last_name
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  });
  

  const columns = [{
    title: 'Name',
    key: 'name',
    render: (shareholder) => `${shareholder.first_name} ${shareholder.last_name}`,
    ...getNameSearchProps()
  }, {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  }, {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
  }, {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
    ellipsis: true,
  }, {
    title: '',
    key: 'actions',
    render: (row) => (
      <div className="text-right">
        <Button
          disabled={row.user_id === props.user.id}
          onClick={() => window.confirm(`You are now going to view the dashboard as ${row.first_name} ${row.last_name}`) && browserHistory.push(createPath(SITE_PATHS.FUND+`?view_as=${row.user_id}`, { id: row.fund_id }))}>
          <Icon type="eye" theme="outlined" />
        </Button>
        <Button
          disabled={row.activated}
          onClick={() => window.confirm(`Are you sure you want to send an activation email to ${row.first_name} ${row.last_name}?`) && props.sendInviteMailAction(row.fund_id, row.user_id)}>
          <Icon type="mail" theme="outlined" />
        </Button>
        <Button 
          onClick={() => browserHistory.push(
            createPath(SITE_PATHS.FUND+SITE_PATHS.SHAREHOLDERS+SITE_PATHS.SHAREHOLDER_EDIT, 
              { id: row.fund_id, shareholderId: row.user_id }))}>
          <Icon type="edit" theme="outlined" />
        </Button>
        <Button type="danger" onClick={() => window.confirm("Are you sure you want to remove this shareholder?") && props.removeShareholderAction(row)}>
          <Icon type="delete" theme="outlined" />
        </Button>
      </div>
      )
  }];
  return (
    <Template menuItem="funds" {...props}>
      <h1>Shareholders</h1>
      <Loader loading={props.loading}>
        <Table
          dataSource={props.shareholders}
          columns={columns}
          size="middle"
          rowKey="id"
        />
      </Loader>
      <Button 
        type="primary" 
        onClick={() => browserHistory.push(createPath(SITE_PATHS.FUND+SITE_PATHS.SHAREHOLDERS+SITE_PATHS.NEW, { id: props.match.params.id }))}
      >
        Add new shareholder <Icon type="plus" />
      </Button>
    </Template>
  )};