// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import type { TGlobalState, TBaseProps } from '../../types';
import { EmailCreateTemplate } from '../../templates';
import { sendShareholderEmailAction, requestShareholdersAction } from '../../../store/actions';

type TProps = TBaseProps & {};

export class EmailCreate extends React.Component<TProps> {
  constructor(props) {
    super(props);
    const fundId = parseInt(props.match.params.id, 10);
    props.requestShareholdersAction(fundId);
  }

  render() {
    return <EmailCreateTemplate {...this.props} />;
  }
}

const mapStateToProps = (state: TGlobalState, ownProps) => {
  const fund = state.fund.fundList.find(x => x.id === parseInt(ownProps.match.params.id));
  const fundUrl = 'https://newflorence.be/f/'+ownProps.match.params.id;
  return {
    email: {
      user_id: state.user.profile.id, 
      fund_id: parseInt(ownProps.match.params.id, 10),
      recipients: state.fund.shareholderList.map(shareholder => shareholder.user_id),
      subject: `[${fund.name}] Monthly Report ${moment().format('MMM')}`,
      body: `
        <p>Beste [:name:],</p>
        <p>Bekijk hier de resultaten van het fonds: <a href='${fundUrl}' target='_blank'>${fundUrl}</a></p>
        <p>${state.user.profile.first_name} ${state.user.profile.last_name}</p>
        `,
    },
    shareholders: state.fund.shareholderList,
    loading: state.fund.shareholderListLoading,
  };
};
export const EmailCreatePage = connect(mapStateToProps, {
  sendShareholderEmailAction,
  requestShareholdersAction,
})(EmailCreate);