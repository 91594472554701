// @flow
import { put, call, takeLatest } from 'redux-saga/effects';
import type { TBaseAction, TUser } from '../../types';
import { login, getUserByCode, activateUser, inviteUser, forgotPassword, resetPassword } from '../../api';
import { genericErrorHandler } from '../../utils';
import { browserHistory } from '../index';
import { SITE_PATHS, USER_ROLES } from '../../config/api.constants';
import { receivedUserValidationAction, receivedUserAction, receivedTokenAction } from '../actions';
import jwt from 'jsonwebtoken';
import { appApi } from '../../config/api.config';

const goToFund = (user, fund_id) => {
  if (user.role === USER_ROLES.USER) {
    browserHistory.push(SITE_PATHS.FUND.replace(':id', fund_id));
  } else if (user.role === USER_ROLES.ADMIN) {
    browserHistory.push(SITE_PATHS.FUNDS);
  }
}

const goHome = () => browserHistory.push(SITE_PATHS.HOME);

function* loginUser(token: string): Generator<*, *, *> {
  const user = jwt.decode(token);
  yield put(receivedTokenAction(token));
  yield put(receivedUserAction(user));
  appApi.defaults.headers.post['x-access-token'] = token;
  appApi.defaults.headers.put['x-access-token'] = token;
  appApi.defaults.headers.get['x-access-token'] = token;
  appApi.defaults.headers.delete['x-access-token'] = token;

  // TODO:
  // Lacking: fund-id in case of user.role = user
  // Currently: hard-coded redirect to fund-id=1
  goToFund(user, 1);
}

export function* loginSaga(action: TBaseAction<TUser>): Generator<*, *, *> {
  try {
    yield put(receivedUserValidationAction(null));
    const response = yield call(login, action.payload);
    const token = response.data;

    localStorage.setItem("NF_AUTO_LOGIN", token);
    yield call(loginUser, token);
  } catch (e) {
    yield put(receivedUserValidationAction(e.response.data.message));
  }
}

export function* autoLoginSaga(): Generator<*, *, *> {
  try {
    const token = localStorage.getItem("NF_AUTO_LOGIN");

    if (token) {
      yield call(loginUser, token);
    }
  } catch (e) {
    yield put(receivedUserValidationAction(e.response.data.message));
  }
}

export function* logoutSaga(action: TAction): Generator<*, *, *> {
  try {
    yield put(receivedTokenAction(''));
    yield put(receivedUserAction({}));
    appApi.defaults.headers.post['x-access-token'] = '';
    appApi.defaults.headers.put['x-access-token'] = '';
    appApi.defaults.headers.delete['x-access-token'] = '';
    localStorage.removeItem("NF_AUTO_LOGIN");
    browserHistory.push(SITE_PATHS.LOGIN);
  } catch (e) {
    console.log(e);
  }
}
export function* getUserByCodeSaga(action: TAction): Generator<*, *, *> {
  try {
    yield put(receivedUserValidationAction(null));
    const response = yield call(getUserByCode, action.payload);
    const user = response.data;
    yield put(receivedUserAction(user));
  } catch (e) {
    if (e.response.data && e.response.data.message) {
      yield put(receivedUserValidationAction(e.response.data.message));
    } else {
      yield put(receivedUserValidationAction("Could not validate user"));
    }
  }
}
export function* activateUserSaga(action: TBaseAction<{code: string, password: string}>): Generator<*, *, *> {
  try {
    yield put(receivedUserValidationAction(null));
    const response = yield call(activateUser, action.payload.code, action.payload.password);
    
    const token = response.data;
    yield call(loginUser, token);
  } catch (e) {
    console.log(e);
    // TODO: VERIFY!!
    yield put(receivedUserValidationAction(e.response.data.message));
  }
}

export function* forgotPasswordSaga(action: TBaseAction<{fundId: string, userId: string}>) {
  try {
    yield put(receivedUserValidationAction(null));
    yield call(forgotPassword, action.payload);
    yield put(receivedUserValidationAction("Uw aanvraag is verzonden. Controleer uw e-mailadres om uw wachtwoord opnieuw in te stellen."));
    setTimeout(goHome, 3000);
  } catch (e) {
    yield put(receivedUserValidationAction("Uw aanvraag is verzonden. Controleer uw e-mailadres om uw wachtwoord opnieuw in te stellen."));
  }
}

export function* resetPasswordSaga(action: TBaseAction<{code: string, password: string}>) {
  try {
    yield put(receivedUserValidationAction(null));
    yield call(resetPassword, action.payload.code, action.payload.password);
    yield put(receivedUserValidationAction("Uw wachtwoord is bijgewerkt, u wordt nu doorgestuurd naar de startpagina."));
    setTimeout(goHome, 3000);
  } catch (e) {
    yield put(receivedUserValidationAction(e.message));
  }
}

export function* sendInviteMailSaga(action: TBaseAction<{fundId: string, userId: string}>) {
  try {
    yield call(inviteUser, action.payload.fundId, action.payload.userId);
  } catch (e) {
    console.log(e);
  }
}

// SAGAS
export function* UserSaga(): Generator<*, *, *> {
  yield takeLatest('USER_ACTIONS.AUTO_LOGIN', genericErrorHandler(autoLoginSaga));
  yield takeLatest('USER_ACTIONS.LOGIN', genericErrorHandler(loginSaga));
  yield takeLatest('USER_ACTIONS.LOGOUT', genericErrorHandler(logoutSaga));
  yield takeLatest('USER_ACTIONS.GET_USER_BY_CODE', genericErrorHandler(getUserByCodeSaga));
  yield takeLatest('USER_ACTIONS.ACTIVATE_USER', genericErrorHandler(activateUserSaga));
  yield takeLatest('USER_ACTIONS.REQUEST_FORGOT_PASSWORD', genericErrorHandler(forgotPasswordSaga));
  yield takeLatest('USER_ACTIONS.REQUEST_RESET_PASSWORD', genericErrorHandler(resetPasswordSaga));
  yield takeLatest('USER_ACTIONS.SEND_INVITE_MAIL', genericErrorHandler(sendInviteMailSaga))
}
