// @flow
import * as React from 'react';
import moment from 'moment';
import { Table, Pagination } from 'antd';
import { Template } from '../Template';
import { Loader } from '../../atoms/Loader';

export const LogsTemplate = (props) => {
  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      render: (date) => moment(date).format("DD-MM-YY HH:mm Z")
      // render: (date) => moment(date).utc().fromNow()
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Exchange',
      dataIndex: 'exchange',
    },
    
  ];
  return (
    <Template menuItem="logs" {...props}>
      <h1>Logs</h1>
      <Loader loading={props.loading}>
        <Table
          dataSource={props.logs}
          columns={columns}
          size="middle"
          rowKey="id"
          rowClassName={record => record.status === 500 ? 'warning' : ''}
          expandedRowRender={record => (
            <div>
              {record.status && <p>Status: {record.status}</p>}
              {record.message && <p>Message: {record.message}</p>}
              {record.exchange && <p>Exchange: {record.exchange}</p>}
              {record.asset && <p>Asset: {record.asset}</p>}
              {record.fund_id && <p>Fund ID: {record.fund_id}</p>}
              {record.user_id && <p>User ID: {record.user_id}</p>}
            </div>
          )}
          pagination={<Pagination
            position="center"
            total={props.logs.length}
            // defaultCurrent={1}
            defaultPageSize={100}
            pageSize={100}
            hideOnSinglePage
            pageSizeOptions={['50', '100']}
            showSizeChanger
          />}
        />
      </Loader>
    </Template>
    );
};