// @flow
import * as React from 'react';
import { Template } from '../Template';
import { FundForm } from '../../atoms/Forms';
import { Loader } from '../../atoms/Loader';

export const FundEditTemplate = (props) => (
  <Template menuItem="funds" {...props}>
    <h1>Edit Fund</h1>
    <Loader loading={props.loading}>
      <FundForm {...props} />
    </Loader>
  </Template>
);