// @flow
import * as React from 'react';
import { Col, Form } from 'antd';
import type { TBaseProps } from '../../types';
import { Template } from './Template';
import { loginAction } from '../../store/actions';
import { LoginForm } from '../atoms/Forms';

type TProps = TBaseProps & {
  form: any,
  loginAction: typeof loginAction,
  validation: string,
};

const Login = (props: TProps) => {
  return (
    <Template menuItem="login" {...props}>
      <Col xs={{ span: 20, offset: 2}} md={{ span: 10, offset: 7 }}>
        <h1>Log in</h1>
        <LoginForm {...props} />
      </Col>
    </Template>
  );
};
export const LoginTemplate = Form.create()(Login);
