// @flow
import * as React from 'react';
import { Layout, Col, Icon } from 'antd';
import type { TBaseProps } from '../../../types';
import { HorizontalMenu } from './HorizontalMenu';
import { UserMenu } from './UserMenu';
import './template.css';
const { Content, Header, Footer } = Layout;

type TProps = TBaseProps & {
  children: any,
  menuItem: string,
};

export const Template = (props: TProps) => (
  <Layout className={"template "+props.menuItem}>
    <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
      <div className="logo"></div>
      <HorizontalMenu {...props} />
      {props.user && props.user.id && <UserMenu {...props} />}
    </Header>
    <Content style={{ padding: '0 50px', marginTop: 64 }}>
      <Col sm={{ span: 1 }} xs={{ span: 0 }} style={{ marginTop: '30px', cursor: 'pointer' }}>
        {props.user 
          && props.user.role === 'admin'
          && props.history.length > 2 
          && <span onClick={() => props.history.goBack()}><Icon type="left" /><span className="show-xl"> back</span></span>}
      </Col>
      <Col sm={{ span: 22 }} xs={{ offset: 1, span: 22 }} style={{ marginTop: '20px' }}>
        {props.children}
      </Col>
    </Content>
    <Footer><i>New Florence CVBA 2018 &copy; - all rights reserved</i></Footer>
  </Layout>
);