import { TPost } from "../../types";

export const requestPostsAction = (fundId: number) : TAction => ({
  payload: fundId,
  type: 'POST_ACTIONS.REQUEST_POSTS',
});

export const receivedPostsAction = (posts: TPost[]) : TBAseAction<TPost[]> => ({
  payload: posts,
  type: 'POST_ACTIONS.RECEIVED_POSTS',
});

export const requestPostAction = (payload: {fundId: number, postId: number}) : TBAseAction<number> => ({
  payload: payload,
  type: 'POST_ACTIONS.REQUEST_POST',
});

export const requestLastPostAction = (fundId: number) : TAction => ({
  payload: fundId,
  type: 'POST_ACTIONS.REQUEST_LAST_POST',
});

export const receivedPostAction = (post: TPost) : TBAseAction<TPost[]> => ({
  payload: post,
  type: 'POST_ACTIONS.RECEIVED_POST',
});

export const savePostFormAction = (post: TPost): TBAseAction<TPost> => ({
  payload: post,
  type: 'POST_ACTIONS.SAVE_POST_FORM',
});

export const removePostAction = (post: TPost): TBAseAction<TPost> => ({
  payload: post,
  type: 'POST_ACTIONS.REMOVE_POST',
});