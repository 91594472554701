// @flow
import * as React from 'react';
import { Template } from '../Template';
import { ShareholderForm } from '../../atoms/Forms';

export const ShareholderCreateTemplate = (props) => (
  <Template menuItem="funds" {...props}>
    <h1>Voeg een nieuwe Shareholder toe</h1>
    <div className="well">
      Maak een nieuwe gebruikersaccount en voeg deze toe aan het huidige fonds. Gebruik dit formulier niet voor bestaande gebruikers.
    </div>
    <div className="space-25" />
    <ShareholderForm {...props} />
  </Template>
);