// @flow
import { put, call, takeLatest } from 'redux-saga/effects';
import { genericErrorHandler, createPath } from '../../utils';
import { SITE_PATHS } from '../../config/api.constants';
import { receivedPostAction, receivedPostsAction, requestPostsAction } from '../actions';
import { browserHistory } from '..';
import { updatePost, createPost, getPost, getLastPost, getPosts, removePost } from '../../api/posts.api';

export function* getPostsSaga(action: TBaseAction<Number>): Generator<*, *, *> {
  const response = yield call(getPosts, action.payload);
  yield put(receivedPostsAction(response.data));
}

export function* getPostSaga(action: TBaseAction<number>): Generator<*, *, *> {
  const response = yield call(getPost, action.payload);
  yield put(receivedPostAction(response.data));
}

export function* getLastPostsSaga(action: TBaseAction<number>): Generator<*, *, *> {
  const response = yield call(getLastPost, action.payload);
  yield put(receivedPostsAction(response.data));
}

export function* savePostSaga(action: TBaseAction<TPost>): Generator<*, *, *> {
  if (action.payload.id) {
    yield call(updatePost, action.payload);
  } else {
    yield call(createPost, action.payload);
  }
  browserHistory.push(createPath(SITE_PATHS.FUND+SITE_PATHS.POSTS, { id: action.payload.fund_id }))
}

export function* removePostSaga(action: TBaseAction<TColdStorage>): Generator<*, *, *> {
  yield call(removePost, action.payload);
  yield put(requestPostsAction(action.payload.fund_id));
}

export function* PostsSaga(): Generator<*, *, *> {
  yield takeLatest('POST_ACTIONS.REQUEST_POSTS', genericErrorHandler(getPostsSaga));
  yield takeLatest('POST_ACTIONS.REQUEST_POST', genericErrorHandler(getPostSaga));
  yield takeLatest('POST_ACTIONS.REQUEST_LAST_POST', genericErrorHandler(getLastPostsSaga));
  yield takeLatest('POST_ACTIONS.SAVE_POST_FORM', genericErrorHandler(savePostSaga));
  yield takeLatest('POST_ACTIONS.REMOVE_POST', genericErrorHandler(removePostSaga));
}