import { TFund } from "../../types";

export const requestFundsAction = () : TAction => ({
  type: 'FUND_ACTIONS.REQUEST_FUNDS',
});

export const receivedFundsAction = (funds: TFund[]) : TBAseAction<TFund[]> => ({
  payload: funds,
  type: 'FUND_ACTIONS.RECEIVED_FUNDS',
});

export const requestFundAction = (fundId: number) : TBAseAction<number> => ({
  payload: fundId,
  type: 'FUND_ACTIONS.REQUEST_FUND',
});

export const receivedFundAction = (fund: TFund) : TBAseAction<TFund[]> => ({
  payload: fund,
  type: 'FUND_ACTIONS.RECEIVED_FUND',
});

export const receivedFundInfoAction = (fund: TFund) : TBAseAction<TFund[]> => ({
  payload: fund,
  type: 'FUND_ACTIONS.RECEIVED_FUND_INFO',
});

export const saveFundFormAction = (fund: TFund): TBAseAction<TFund> => ({
  payload: fund,
  type: 'FUND_ACTIONS.SAVE_FUND_FORM',
});

export const removeFundAction = (fund: TFund): TBAseAction<TFund> => ({
  payload: fund,
  type: 'FUND_ACTIONS.REMOVE_FUND',
});