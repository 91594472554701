// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState, TUser, TBaseProps } from '../../types';
import { NotFoundTemplate } from '../../templates';

type TProps = TBaseProps & {
  user: TUser,
};

const NotFound = (props: TProps) =>
  <NotFoundTemplate {...props}/>;

const mapStateToProps = (state: TGlobalState) => ({
  user: state.user.profile,
});
export const NotFoundPage = connect(mapStateToProps, {})(NotFound);