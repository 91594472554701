// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState, TBaseProps } from '../../types';
import { ExchangeCreateTemplate } from '../../templates/Assets';
import { saveExchangeFormAction } from '../../../store/actions';

type TProps = TBaseProps & {};

const ExchangeCreate = (props: TProps) => (
  <ExchangeCreateTemplate {...props}/>
);

const mapStateToProps = (state: TGlobalState) => ({
  exchange: { type: '', api_key: '', api_secret: '' },
});
export const ExchangeCreatePage = connect(mapStateToProps, {
  saveExchangeFormAction
})(ExchangeCreate);