/* eslint-disable import/first */
import React from 'react';
import dotenv from 'dotenv';
dotenv.config();
// import I18n from 'i18n-js';
import { hot } from 'react-hot-loader'; // eslint-disable-line import/no-extraneous-dependencies
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';

import './index.css';
// import 'antd/dist/antd.css';

import * as serviceWorker from './serviceWorker';
import { scriptLoader } from './config/settings';
import store, { browserHistory } from './store';
import Routes from './config/app.routes';

// import en from './translations/en.json';
// import nl from './translations/nl.json';
// import fr from './translations/fr.json';

// I18n.defaultLocale = 'en';
// I18n.fallbacks = true;
// I18n.translations = { en, fr, nl };

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={browserHistory}>
      <Routes />
    </ConnectedRouter>
  </Provider>
);

if (process.env.NODE_ENV === 'production') {
  const HotApp = hot(module)(App);
  scriptLoader(() => {
    render(<HotApp />, document.getElementById('root'));
  });
} else {
  scriptLoader(() => {
    render(<App />, document.getElementById('root'));
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();