import * as React from 'react';
import { Form, Input, Button } from 'antd';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const FormItem = Form.Item;

class Post extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = props.post;
    if (props.post.body && props.post.body.length > 0) {
      const contentBlock = htmlToDraft(props.post.body);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        this.state.body = editorState;
      }
    }
  }
  
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form
        className="fund-form"
        onSubmit={e => {
          e.preventDefault();
          this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
              values.body = draftToHtml(convertToRaw(this.state.body.getCurrentContent()));
              if (this.props.match.params.postId) {
                this.props.savePostFormAction({ 
                  id: parseInt(this.props.match.params.postId),
                  ...this.props.post,
                  ...values,
                });
              } else {
                this.props.savePostFormAction({
                  ...this.props.post,
                  ...values,
                });
              }
            }
          });
        }}
      >
        <FormItem label="Title">
          {getFieldDecorator('title', {
            initialValue: this.state.title,
            rules: [{
              required: true, min: 3, whitespace: true, message: "Please provide a valid title" 
            }],
          })(
            <Input />
          )}
        </FormItem>
        <FormItem label="Body">
          <Editor
            editorState={this.state.body}
            toolbarClassName="editorToolbar"
            wrapperClassName="editorWrapper"
            editorClassName="editor"
            onEditorStateChange={(body) => this.setState({ body })}
          />
        </FormItem>
        <FormItem  label="Preview">
          <h2>{this.state.title}</h2>
          {this.state.body && <span dangerouslySetInnerHTML={{__html: draftToHtml(convertToRaw(this.state.body.getCurrentContent()))}} />}
          <hr />
          {this.state.body && <div><small>{draftToHtml(convertToRaw(this.state.body.getCurrentContent()))}</small></div>}
        </FormItem>
        <FormItem >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </FormItem>
      </Form>
    );
  }
}
export const PostForm = Form.create()(Post);