// @flow
import { combineReducers } from 'redux';
import { listReducer, loaderReducer } from './generic';

const reducer = combineReducers({
  logList: listReducer('LOGS_ACTIONS.RECEIVED_LOGS'),
  logListLoading: loaderReducer('LOGS_ACTIONS.REQUEST_LOGS', 'LOGS_ACTIONS.RECEIVED_LOGS'),
  
  emailList: listReducer('LOGS_ACTIONS.RECEIVED_EMAILS'),
  emailListLoading: loaderReducer('LOGS_ACTIONS.REQUEST_EMAILS', 'LOGS_ACTIONS.RECEIVED_EMAILS'),
});

export default reducer;
