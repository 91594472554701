// @flow
import * as React from 'react';
import { Template } from '../Template';
import { ExchangeForm } from '../../atoms/Forms';

export const ExchangeCreateTemplate = (props) => (
  <Template menuItem="funds" {...props}>
    <h1>Add Exchange</h1>
    <ExchangeForm {...props} />
  </Template>
  );