// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState, TBaseProps } from '../../types';
import { LogsTemplate } from '../../templates/Logs';
import { requestLogsAction } from '../../../store/actions';

type TProps = TBaseProps & {};

class Logs extends React.Component<TProps, {}> {
  constructor(props) {
    super(props);
    const fundId = parseInt(props.match.params.id, 10); 
    props.requestLogsAction({ fund_id: fundId });
  }
  render() {
    return <LogsTemplate {...this.props}/>
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  logs: state.logs.logList,
  loading: state.logs.logListLoading,
});
export const LogsPage = connect(mapStateToProps, {
  requestLogsAction,
})(Logs);