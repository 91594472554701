// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState, TBaseProps } from '../../types';
import { ColdStorageCreateTemplate } from '../../templates/Assets';
import { saveColdStorageFormAction } from '../../../store/actions';

type TProps = TBaseProps & {};

const ColdStorageCreate = (props: TProps) => (
  <ColdStorageCreateTemplate {...props}/>
);

const mapStateToProps = (state: TGlobalState) => ({
  storage: { name: '', address: '', amount: 0, asset: '', btc_value_src: null },
  exchangeList: state.fund.exchangeList,
});
export const ColdStorageCreatePage = connect(mapStateToProps, {
  saveColdStorageFormAction,
})(ColdStorageCreate);