// @flow
export const createPath = (path: string, pathVars: any) => {
  let newPath = path;
  if (newPath && pathVars) {
    Object.keys(pathVars).map(key => {
      newPath = newPath.replace(`:${key}`, pathVars[key]);
      return key;
    });
    console.log(path, newPath);
    return newPath;
  }
  
  return '/url-creation-error';
};
