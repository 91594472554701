// @flow
import * as React from 'react';
import { Col } from 'antd';
import { Template } from '../Template';
import { ResetPasswordForm } from '../../atoms/Forms';

export const ResetPasswordTemplate = (props) => (
  <Template menuItem="reset-password" {...props}>
  <Col xs={{ span: 20, offset: 2}} md={{ span: 14, offset: 5 }} xl={{ span: 10, offset: 7 }}>
      <h1>Stel je wachtwoord opnieuw in</h1>
      {!props.validation && <p>Kies een nieuw wachtwoord en activeer uw account om toegang te krijgen tot het dashboard.</p>}
      <ResetPasswordForm {...props} />
    </Col>
  </Template>
  );