import * as React from 'react';
import { Form, Input, Button, Select } from 'antd';
import _ from 'lodash';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const FormItem = Form.Item;
const { Option } = Select;

class Email extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = props.email;
    if (props.email.body && props.email.body.length > 0) {
      const contentBlock = htmlToDraft(props.email.body);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        this.state.body = editorState;
      }
    }
  }
  
  render() {
    const { getFieldDecorator } = this.props.form;
    const uniqueShareholdersInfo = _.uniqBy(this.props.shareholders, 'user_id');
    const recipientsValue = uniqueShareholdersInfo
      .filter(shareholder => shareholder.role === 'SHAREHOLDER')
      .map(shareholder => shareholder.user_id);
    const recipientsOptions = uniqueShareholdersInfo
      .map(shareholder => (
        <Option
          key={shareholder.user_id}
          value={shareholder.user_id}>
            {shareholder.first_name} {shareholder.last_name}
        </Option>));
    
    return (
      <Form
        className="fund-form"
        onSubmit={e => {
          e.preventDefault();
          this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
              values.body = draftToHtml(convertToRaw(this.state.body.getCurrentContent()));
              this.props.sendShareholderEmailAction({ 
                ...this.props.email,
                ...values,
              });
            }
          });
        }}
      >
        <FormItem label="Subject">
          {getFieldDecorator('subject', {
            initialValue: this.state.subject,
            rules: [{
              required: true, min: 3, whitespace: true, message: "Please provide a valid subject" 
            }],
          })(
            <Input />
          )}
        </FormItem>
        <FormItem label="Recipients">
          {getFieldDecorator('recipients', {
            initialValue: recipientsValue,
            value: this.state.recipients,
            rules: [{
              required: true,
              type: 'array',
            }]
          })(
            <Select
              mode="multiple"
              placeholder="Selecteer ontvangers"
            >
              {recipientsOptions}
            </Select>
          )}
        </FormItem>
        <FormItem label="Body">
          <Editor
            editorState={this.state.body}
            toolbarClassName="editorToolbar"
            wrapperClassName="editorWrapper"
            editorClassName="editor"
            onEditorStateChange={(body) => this.setState({ body })}
          />
        </FormItem>
        <FormItem >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </FormItem>
      </Form>
    );
  }
}
export const EmailForm = Form.create()(Email);