// @flow
import $script from 'scriptjs';

const EXTERNAL_SCRIPTS = [];

export const scriptLoader = (onLoad: Function) => {
  $script.get(EXTERNAL_SCRIPTS, () => {
    $script.done(onLoad);
  });
};
