// @flow
import * as React from 'react';
import { Icon } from 'antd';

export const Loader = (props: { loading: boolean, text?: string, children: any }) => props.loading
  ? (
    <div className="text-center">
      <div className="space-60" />
      <Icon type="loading" theme="outlined" />
      {props.text && <p>{props.text}</p>}
      <div className="space-60" />
    </div>
    )
  : props.children;