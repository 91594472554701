// @flow
import * as React from 'react';
import { Col } from 'antd';
import { Template } from '../Template';
import { ForgotPasswordForm } from '../../atoms/Forms';

export const ForgotPasswordTemplate = (props) => (
  <Template menuItem="forgot" {...props}>
    <Col xs={{ span: 20, offset: 2}} md={{ span: 14, offset: 5 }} xl={{ span: 12, offset: 6 }}>
      <h1>Paswoord vergeten</h1>
      <p>Vul het email adres in waarmee je bent ingeschreven. Geef je email We sturen u een e-mail waarmee u uw wachtwoord opnieuw kunt instellen.</p>

      <ForgotPasswordForm {...props} />
    </Col>
  </Template>
  );