// @flow
import { fork, all } from 'redux-saga/effects';
import { UserSaga } from './user.sagas';
import { FundSaga } from './fund.sagas';
import { ChartsSaga } from './charts.sagas';
import { LogsSaga } from './logs.sagas';
import { PostsSaga } from './posts.sagas';

export default function* rootSaga(): Generator<*, *, *> {
  yield all([
    fork(UserSaga),
    fork(FundSaga),
    fork(ChartsSaga),
    fork(LogsSaga),
    fork(PostsSaga),
  ]);
}
