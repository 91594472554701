import * as React from 'react';
import { Form, Input, Button } from 'antd';
import { formItemLayout } from './FormElements';

const FormItem = Form.Item;

class ForgotPassword extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = { password: '' };
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form
        className="activation-form"
        onSubmit={e => {
          e.preventDefault();
          this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
              this.props.requestForgotPasswordAction(values.email);
            }
          });
        }}
      >
        <FormItem {...formItemLayout} label="Email">
          {getFieldDecorator('email', {
            initialValue: this.state.email,
            rules: [{
              type: 'email', message: 'Dit is geen geldig e-mail adres',
            }, {
              required: true, message: 'Geef een e-mailadres op',
            }],
          })(
            <Input type="email" />
          )}
        </FormItem>
        {this.props.validation && <FormItem className="validation-message">{this.props.validation}</FormItem>}
        <FormItem {...formItemLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </FormItem>
      </Form>
    );
  }
}
export const ForgotPasswordForm = Form.create()(ForgotPassword);