// @flow
import type { TShareholder, TShareholderKey } from '../../types';

export const requestShareholdersAction = (fundId: number) : TAction => ({
  payload: fundId,
  type: 'FUND_ACTIONS.REQUEST_SHAREHOLDERS',
});


// UNTESTED
export const receivedShareholdersAction = (shareholders: TShareholder[]) : TBAseAction<TShareholder[]> => ({
  payload: shareholders,
  type: 'FUND_ACTIONS.RECEIVED_SHAREHOLDERS',
});

export const receivedShareholderValidationAction = (message: string) : TBAseAction<string> => ({
  payload: message,
  type: 'FUND_ACTIONS.RECEIVED_SHAREHOLDER_VALIDATION',
});

// export const requestShareholderAction = (shareholderKey: TShareholderKey) : TBAseAction<TShareholderKey> => ({
//   payload: shareholderKey,
//   type: 'FUND_ACTIONS.REQUEST_SHAREHOLDER',
// });

// export const receivedShareholderAction = (shareholder: TShareholder) : TBAseAction<TShareholder[]> => ({
//   payload: shareholder,
//   type: 'FUND_ACTIONS.RECEIVED_SHAREHOLDER',
// });

export const saveShareholderFormAction = (shareholder: TShareholder): TBAseAction<TShareholder> => ({
  payload: shareholder,
  type: 'FUND_ACTIONS.SAVE_SHAREHOLDER_FORM',
});

export const removeShareholderAction = (shareholderKey: TShareholderKey): TBAseAction<TShareholder> => ({
  payload: shareholderKey,
  type: 'FUND_ACTIONS.REMOVE_SHAREHOLDER',
});

export const sendInviteMailAction = (fundId, userId) => ({
  payload: {
    fundId,
    userId,
  },
  type: 'USER_ACTIONS.SEND_INVITE_MAIL',
})

export const sendShareholderEmailAction = (payload) : TAction => ({
  type: 'FUND_ACTIONS.SEND_SHAREHOLDER_MAIL',
  payload,
});
  