// @flow
import * as React from 'react';
import { Menu, Dropdown, Icon } from 'antd';

export const UserMenu = (props) => (
  <Dropdown
    className="user-menu"
    placement="bottomRight"
    overlay={
      <Menu
        defaultSelectedKeys={[props.menuItem]}
      >
      <Menu.Item key="home">
          <div onClick={props.logoutAction}>
            Log out
          </div>
      </Menu.Item>
    </Menu>
  }>
    {/* eslint-disable-next-line */}
    <span className="ant-dropdown-link">
      <span className="hide-mobile">{props.user.email}</span>
      <Icon type="user" className="only-mobile" style={{fontSize: '1.5em'}}/> <Icon type="down" />
    </span>
  </Dropdown>
);