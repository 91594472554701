// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState, TBaseProps } from '../../types';
import { PostsTemplate } from '../../templates/Posts';
import { requestPostsAction, removePostAction } from '../../../store/actions';

type TProps = TBaseProps & {};

class Posts extends React.Component<TProps, {}> {
  constructor(props) {
    super(props);
    const fundId = parseInt(props.match.params.id, 10); 
    props.requestPostsAction(fundId);
  }
  render() {
    return <PostsTemplate {...this.props}/>
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  isAdmin: state.user.profile.role === 'admin',
  posts: state.posts.postList,
  loading: state.posts.postListLoading,
});
export const PostsPage = connect(mapStateToProps, {
  requestPostsAction,
  removePostAction,
})(Posts);