// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState, TBaseProps } from '../../types';
import { FundCreateTemplate } from '../../templates/Funds';
import { saveFundFormAction } from '../../../store/actions';

type TProps = TBaseProps & {};

export const FundCreate = (props: TProps) => <FundCreateTemplate {...props} />

const mapStateToProps = (state: TGlobalState) => ({
  fund: { name: '', type: 'PRIVATE' },
});
export const FundCreatePage = connect(mapStateToProps, {
  saveFundFormAction,
})(FundCreate);