// @flow
import * as React from 'react';
import { Template } from './Template';
import Redirect from 'react-router-dom/Redirect';
import { SITE_PATHS } from '../../config/api.constants';

export const HomeTemplate = (props) => (
  <Template menuItem="home" {...props}>
    <Redirect to={SITE_PATHS.LOGIN}/>
  </Template>
  );