// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState, TUser, TBaseProps } from '../../types';
import { HomeTemplate } from '../templates';

type TProps = TBaseProps & {
  user: TUser,
};

const Home = (props: TProps) =>
  <HomeTemplate {...props}/>;

const mapStateToProps = (state: TGlobalState) => ({
  user: state.user.profile,
});
export const HomePage = connect(mapStateToProps, {})(Home);
