// @flow
import * as React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import moment from 'moment';
import type { TGlobalState, TBaseProps } from '../../types';
import { FundTemplate } from '../../templates/Funds';
import { requestFundAction, requestIndexAction, requestAssetsAction, requestLastPostAction } from '../../../store/actions';
type TProps = TBaseProps & {};

export class Fund extends React.Component<TProps, {}> {
  constructor(props) {
    super(props);
    const fundId = parseInt(props.match.params.id, 10);
    props.requestFundAction(fundId);
    props.requestLastPostAction(fundId);
    if (props.location.search && queryString.parse(props.location.search)) {
      const viewAs = parseInt(queryString.parse(props.location.search).view_as, 10);
      props.requestIndexAction({ fund_id: fundId, user_id: viewAs });
      // props.requestIndexAction({ fund_id: fundId, user_id: viewAs, fromDate: "2018-01-01" });
      props.requestAssetsAction({ fund_id: fundId, user_id: viewAs });
    } else {
      props.requestIndexAction({ fund_id: fundId, user_id: props.user.id });
      // props.requestIndexAction({ fund_id: fundId, user_id: props.user.id, fromDate: "2018-01-01" });
      props.requestAssetsAction({ fund_id: fundId, user_id: props.user.id });
    }
  }

  requestIndexPeriod(period) {
    let fromDate;
    if (period === 'MONTH') {
      fromDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
    } else if (period === 'WEEK') {
      fromDate = moment().subtract(1, 'weeks').format('YYYY-MM-DD');
    }
    const fundId = parseInt(this.props.match.params.id, 10);
    if (this.props.location.search && queryString.parse(this.props.location.search)) {
      const viewAs = parseInt(queryString.parse(this.props.location.search).view_as, 10);
      this.props.requestIndexAction({ fund_id: fundId, user_id: viewAs, fromDate });
    } else {
      this.props.requestIndexAction({ fund_id: fundId, user_id: this.props.user.id, fromDate });
    }
  }

  render() {
    return <FundTemplate {...this.props} requestIndexPeriod={(period) => this.requestIndexPeriod(period)} />;
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  fund: state.fund.fund,
  info: state.fund.info,
  loading: state.fund.loading,
  index: state.charts.indexData,
  indexLoading: state.charts.indexLoading,
  assets: state.charts.assetsData,
  assetsLoading: state.charts.assetsLoading,
  posts: state.posts.postList,
  postListLoading: state.posts.postListLoading,
  type: state.charts.type,
});
export const FundPage = connect(mapStateToProps, {
  requestFundAction,
  requestIndexAction,
  requestAssetsAction,
  requestLastPostAction,
})(Fund);