import * as React from 'react';
import { Form, Select, Input, Button } from 'antd';
import { formItemLayout } from './FormElements';

const FormItem = Form.Item;
const Option = Select.Option;

class Fund extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = props.fund;
  }
  
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form
        className="fund-form"
        onSubmit={e => {
          e.preventDefault();
          this.props.form.validateFieldsAndScroll((err, values) => {
            const fund = { ...values, posts: parseInt(values.posts, 10) };
            if (!err) {
              if (this.props.match.params.id) {
                this.props.saveFundFormAction({ id: parseInt(this.props.match.params.id), ...fund });
              } else {
                this.props.saveFundFormAction(fund);
              }
            }
          });
        }}
      >
        <FormItem {...formItemLayout} label="Name">
          {getFieldDecorator('name', {
            initialValue: this.state.name,
            rules: [{
              required: true, min: 3, whitespace: true, message: "Please provide a valid name" 
            }],
          })(
            <Input />
          )}
        </FormItem>
        <FormItem {...formItemLayout}  label="Type">
          {getFieldDecorator('type', {
            initialValue: this.state.type,
            value: this.state.type,
            rules: [{
              required: false,
            }],
          })(
            <Select style={{ minWidth: '120px' }}>
              <Option value="POOL">Pool</Option>
              <Option value="PRIVATE">Private</Option>
            </Select>,
          )}
        </FormItem>
        <FormItem {...formItemLayout}  label="# of Posts">
          {getFieldDecorator('posts', {
            initialValue: this.state.posts,
            value: this.state.posts,
            rules: [{
              required: false,
            }],
          })(
            <Select>
              <Option value="0">No Posts</Option>
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
            </Select>,
          )}
        </FormItem>
        {/*getFieldValue('type') === 'PRIVATE' && (
          <FormItem {...formItemLayout} label="Name">
          {getFieldDecorator('name', {
            initialValue: this.state.name || this.props.fund.name,
          })(
            <Input />
          )}
        </FormItem>
          )*/}
        <FormItem {...formItemLayout}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </FormItem>
      </Form>
    );
  }
}
export const FundForm = Form.create()(Fund);