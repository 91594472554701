// @flow
import { combineReducers } from 'redux';
import { listReducer, loaderReducer, objectReducer } from './generic';

const reducer = combineReducers({
  post: objectReducer('POST_ACTIONS.RECEIVED_POST'),
  loading: loaderReducer('POST_ACTIONS.REQUEST_POST', 'POST_ACTIONS.RECEIVED_POST'),

  postList: listReducer('POST_ACTIONS.RECEIVED_POSTS'),
  postListLoading: loaderReducer('POST_ACTIONS.REQUEST_POSTS', 'POST_ACTIONS.RECEIVED_POSTS'),
});

export default reducer;
