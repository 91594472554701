// @flow
import * as React from 'react';
import { Table, Button, Icon } from 'antd';
import { Template } from '../Template';
import { browserHistory } from '../../../store';
import { SITE_PATHS } from '../../../config/api.constants';
import { createPath, truncate } from '../../../utils';
import { Loader } from '../../atoms/Loader';

export const AssetsTemplate = (props) => {
  const exchangeColumns = [{
    title: 'Name',
    dataIndex: 'type',
    key: 'type',
  }, {
    title: 'Key',
    dataIndex: 'api_key',
    key: 'api_key',
    render: (key) => truncate(key, 16)
  }, {
    title: '',
    key: 'actions',
    render: (row) => (
      <div className="text-right">
        <Button
          onClick={() => browserHistory.push(
            createPath(SITE_PATHS.FUND+SITE_PATHS.ASSETS+SITE_PATHS.EXCHANGE+SITE_PATHS.EXCHANGE_EDIT, 
              { id: props.match.params.id, exchangeId: row.id }))}>
          <Icon type="edit" theme="outlined" />
        </Button>
        <Button type="danger" onClick={() => window.confirm("Are you sure?") && props.removeExchangeAction(row)}>
          <Icon type="delete" theme="outlined" />
        </Button>
      </div>
      )
  }];
  
  const storageColumns = [{
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  }, {
    title: 'Balance',
    key: 'balance',
    render: (storage) => storage.amount+' '+ storage.asset.toUpperCase(),
  }, {
    title: '',
    key: 'actions',
    render: (row) => (
      <div className="text-right">
        <Button
          onClick={() => browserHistory.push(
            createPath(SITE_PATHS.FUND+SITE_PATHS.ASSETS+SITE_PATHS.STORAGE+SITE_PATHS.STORAGE_EDIT, 
              { id: props.match.params.id, storageId: row.id }))}>
          <Icon type="edit" theme="outlined" />
        </Button>
        <Button type="danger" onClick={() => window.confirm("Are you sure you want to delete the address "+row.address+"?") && props.removeColdStorageAction(row)}>
          <Icon type="delete" theme="outlined" />
        </Button>
      </div>
      )
  }];
  return (
    <Template menuItem="funds" {...props}>
      <h1>Manage Assets</h1>
      <h2>Exchanges</h2>
      <Loader loading={props.exchangesLoading}>
        <Table
          dataSource={props.exchanges}
          columns={exchangeColumns}
          pagination={false}
          size="middle"
          rowKey="id"
        />
      </Loader>
      <div className="space-25" />
      <Button 
        onClick={() => browserHistory.push(
          createPath(SITE_PATHS.FUND+SITE_PATHS.ASSETS+SITE_PATHS.EXCHANGE+SITE_PATHS.NEW, 
            { id: props.match.params.id }))}>
        Add Exchange <Icon type="plus"/>
      </Button>
      <div className="space-50" />

      <h2>Cold Storage</h2>
      <Loader loading={props.storagesLoading}>
        <Table
          dataSource={props.storages}
          columns={storageColumns}
          pagination={false}
          size="middle"
          rowKey="id"
        />
      </Loader>
      <div className="space-25" />
      <Button 
        onClick={() => browserHistory.push(
          createPath(SITE_PATHS.FUND+SITE_PATHS.ASSETS+SITE_PATHS.STORAGE+SITE_PATHS.NEW, 
            { id: props.match.params.id }))}>
        Add Cold Storage <Icon type="plus"/>
      </Button>
    </Template>
  )};