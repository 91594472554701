// @flow
import * as axios from 'axios';
import I18n from 'i18n-js';
import { BASE_URL } from './api.constants';

export const appApi = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Accept-Language': I18n.locale,
  },
  timeout: 0,
});
