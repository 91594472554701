// @flow
import * as React from 'react';
import { Template } from '../Template';
import { PostForm } from '../../atoms/Forms';
import { Loader } from '../../atoms/Loader';

export const PostEditTemplate = (props) => (
  <Template menuItem="funds" {...props}>
    <h1>Edit Post</h1>
    <Loader loading={props.loading}>
      <PostForm {...props} />
    </Loader>
  </Template>
);