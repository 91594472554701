import * as React from 'react';
import { Form, Input, Button } from 'antd';
import { formItemLayout } from './FormElements';

const FormItem = Form.Item;

class ResetPassword extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = { password: '' };
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return this.props.validation
      ? <FormItem className="validation-message">{this.props.validation}</FormItem>
      : (
        <Form
          className="reset-password-form"
          onSubmit={e => {
            e.preventDefault();
            this.props.form.validateFieldsAndScroll((err, values) => {
              if (!err) {
                this.props.requestResetPasswordAction(this.props.code, values.password);
              }
            });
          }}
        >
          <FormItem {...formItemLayout} label="Paswoord">
            {getFieldDecorator('password', {
              initialValue: this.state.password,
              rules: [{
                required: true, min: 3, whitespace: true, message: "Vul een geldig wachtwoord in" 
              }],
            })(
              <Input type="password" />
            )}
          </FormItem>
          {this.props.validation && <FormItem className="validation-message">{this.props.validation}</FormItem>}
          <FormItem {...formItemLayout}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </FormItem>
        </Form>
      )
    ;
  }
}
export const ResetPasswordForm = Form.create()(ResetPassword);