// @flow
import 'babel-polyfill'; // https://mysticcoders.com/blog/fixing-regenerator-runtime-error-integrating-redux-saga
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';

import reducer from './reducers';
import rootSaga from './sagas';
import { autoLoginAction } from './actions';

const historyListener = () => createBrowserHistory();
export const browserHistory = historyListener();

const sagaMiddleware = createSagaMiddleware();

// Build the middleware for intercepting and dispatching navigation actions
const routerMiddlewareConfig = routerMiddleware(browserHistory);
const composeEnhancers = (process.env.NODE_ENV === 'development')
  ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose)
  : compose;
const store = createStore(
  reducer,
  composeEnhancers(
    applyMiddleware(sagaMiddleware),
    applyMiddleware(routerMiddlewareConfig),
  )
);
if (module.hot && process.env.NODE_ENV === 'development') {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept('./reducers', () => {
    const nextRootReducer = require('./reducers/index');
    store.replaceReducer(nextRootReducer);
  });
}
sagaMiddleware.run(rootSaga);
store.dispatch(autoLoginAction());

export default store;
