// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState, TBaseProps } from '../../types';
import { PostEditTemplate } from '../../templates/Posts';
import { savePostFormAction, requestPostAction } from '../../../store/actions';

type TProps = TBaseProps & {};

export class PostEdit extends React.Component<TProps, {}> {
  constructor(props) {
    super(props);
    const fundId = parseInt(props.match.params.id, 10); 
    const postId = parseInt(props.match.params.postId, 10); 
    props.requestPostAction({fundId, postId});
  }

  render() {
    return <PostEditTemplate {...this.props} />;
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  post: state.posts.post,
  loading: state.posts.loading
});
export const PostEditPage = connect(mapStateToProps, {
  savePostFormAction,
  requestPostAction,
})(PostEdit);