// @flow
import { put, call, takeLatest, select } from 'redux-saga/effects';
import type { TBaseAction, TEmail } from '../../types';
import { genericErrorHandler, createPath } from '../../utils';
import {
  receivedFundsAction,
  receivedFundAction,
  receivedShareholdersAction,
  receivedExchangesAction,
  receivedColdStorageAction,
  requestShareholdersAction,
  requestExchangesAction,
  requestColdStorageAction,
  requestFundsAction,
  receivedShareholderValidationAction,
  receivedInventoryAction,
} from '../actions';
import {
  getFunds,
  getFund,
  getFundAdmin,
  createFund,
  updateFund,
  removeFund,

  getShareholders,
  createShareholder,
  updateShareholder,
  removeShareholder,

  getExchanges,
  createExchange,
  updateExchange,
  removeExchange,

  getColdStorages,
  updateColdStorage,
  createColdStorage,
  removeColdStorage,
  getInventory,
  sendShareholderEmail,
} from '../../api';
import { browserHistory } from '..';
import { SITE_PATHS, USER_ROLES } from '../../config/api.constants';

// FUND
export function* getFundsSaga(): Generator<*, *, *> {
  const response = yield call(getFunds);
  yield put(receivedFundsAction(response.data));
}

export function* getFundSaga(action: TBaseAction<number>): Generator<*, *, *> {
  const user = yield select(state => state.user.profile);
  console.log("get fund for ", user.role);

  if (user.role === USER_ROLES.ADMIN) {
    const response = yield call(getFundAdmin, action.payload);
    yield put(receivedFundAction(response.data));
  }
  else if (user.role === USER_ROLES.USER) {
    const response = yield call(getFund, action.payload);
    yield put(receivedFundAction(response.data));
  }

}

export function* saveFundSaga(action: TBaseAction<TFund>): Generator<*, *, *> {
  if (action.payload.id) {
    yield call(updateFund, action.payload);
  } else {
    yield call(createFund, action.payload);
  }
  browserHistory.push(SITE_PATHS.FUNDS)
}

export function* removeFundSaga(action: TBaseAction<TColdStorage>): Generator<*, *, *> {
  yield call(removeFund, action.payload);
  yield put(requestFundsAction(action.payload.fund_id));
}


// SHAREHOLDER
export function* getShareholdersSaga(action: TBaseAction<number>): Generator<*, *, *> {
  const response = yield call(getShareholders, action.payload);
  yield put(receivedShareholdersAction(response.data));
}

export function* saveShareholderSaga(action: TBaseAction<TShareholder>): Generator<*, *, *> {
  try {
    if (action.payload.user_id) {
      yield call(updateShareholder, action.payload);
    } else {
      yield call(createShareholder, action.payload);
    }
    browserHistory.push(createPath(SITE_PATHS.FUND+SITE_PATHS.SHAREHOLDERS, { id: action.payload.fund_id }))
  } catch (e) {
    console.log("saved shareholder?", e.response.data, e);
    if (e.response.data && e.response.data.message) {
      yield put(receivedShareholderValidationAction(e.response.data.message));
    } else {
      yield put(receivedShareholderValidationAction("Could not save shareholder due to an unexpected error."));
    }
  }
}

export function* removeShareholderSaga(action: TBaseAction<TShareholderKey>): Generator<*, *, *> {
  yield call(removeShareholder, action.payload);
  yield put(requestShareholdersAction(action.payload.fund_id));
}


// EXCHANGE
export function* getExchangesSaga(action: TBaseAction<number>): Generator<*, *, *> {
  const response = yield call(getExchanges, action.payload);
  yield put(receivedExchangesAction(response.data));
}

export function* saveExchangeSaga(action: TBaseAction<TShareholder>): Generator<*, *, *> {
  if (action.payload.id) {
    yield call(updateExchange, action.payload);
  } else {
    yield call(createExchange, action.payload);
  }
  browserHistory.push(createPath(SITE_PATHS.FUND+SITE_PATHS.ASSETS, { id: action.payload.fund_id }))
}

export function* removeExchangeSaga(action: TBaseAction<TExchange>): Generator<*, *, *> {
  yield call(removeExchange, action.payload);
  yield put(requestExchangesAction(action.payload.fund_id));
}

// COLD STORAGE
export function* getColdStoragesSaga(action: TBaseAction<number>): Generator<*, *, *> {
  const response = yield call(getColdStorages, action.payload);
  yield put(receivedColdStorageAction(response.data));
}

export function* saveColdStorageSaga(action: TBaseAction<TColdStorage>): Generator<*, *, *> {
  if (action.payload.id) {
    yield call(updateColdStorage, action.payload);
  } else {
    yield call(createColdStorage, action.payload);
  }
  browserHistory.push(createPath(SITE_PATHS.FUND+SITE_PATHS.ASSETS, { id: action.payload.fund_id }))
}

export function* removeColdStorageSaga(action: TBaseAction<TColdStorage>): Generator<*, *, *> {
  yield call(removeColdStorage, action.payload);
  yield put(requestColdStorageAction(action.payload.fund_id));
}

export function* sendShareholderMailSaga(action: TBaseAction<TEmail>): Generator<*, *, *> {
  yield call(sendShareholderEmail, action.payload);
  browserHistory.push(createPath(SITE_PATHS.FUND+SITE_PATHS.EMAILS, { id: action.payload.fund_id }));
}

// INVENTORY
export function* getInventorySaga(action: TBaseAction<number>): Generator<*, *, *> {
  const response = yield call(getInventory, action.payload);
  yield put(receivedInventoryAction(response.data));
}

// SAGAS
export function* FundSaga(): Generator<*, *, *> {
  yield takeLatest('FUND_ACTIONS.REQUEST_FUNDS', genericErrorHandler(getFundsSaga));
  yield takeLatest('FUND_ACTIONS.REQUEST_FUND', genericErrorHandler(getFundSaga));
  yield takeLatest('FUND_ACTIONS.SAVE_FUND_FORM', genericErrorHandler(saveFundSaga));
  yield takeLatest('FUND_ACTIONS.REMOVE_FUND', genericErrorHandler(removeFundSaga));

  yield takeLatest('FUND_ACTIONS.REQUEST_SHAREHOLDERS', genericErrorHandler(getShareholdersSaga));
  yield takeLatest('FUND_ACTIONS.SAVE_SHAREHOLDER_FORM', genericErrorHandler(saveShareholderSaga));
  yield takeLatest('FUND_ACTIONS.REMOVE_SHAREHOLDER', genericErrorHandler(removeShareholderSaga));
  
  yield takeLatest('FUND_ACTIONS.REQUEST_EXCHANGES', genericErrorHandler(getExchangesSaga));
  yield takeLatest('FUND_ACTIONS.SAVE_EXCHANGE_FORM', genericErrorHandler(saveExchangeSaga));
  yield takeLatest('FUND_ACTIONS.REMOVE_EXCHANGE', genericErrorHandler(removeExchangeSaga));
  
  yield takeLatest('FUND_ACTIONS.REQUEST_COLD_STORAGES', genericErrorHandler(getColdStoragesSaga));
  yield takeLatest('FUND_ACTIONS.SAVE_COLD_STORAGE_FORM', genericErrorHandler(saveColdStorageSaga));
  yield takeLatest('FUND_ACTIONS.REMOVE_COLD_STORAGE', genericErrorHandler(removeColdStorageSaga));
  
  yield takeLatest('FUND_ACTIONS.REQUEST_INVENTORY', genericErrorHandler(getInventorySaga));
  yield takeLatest('FUND_ACTIONS.SEND_SHAREHOLDER_MAIL', genericErrorHandler(sendShareholderMailSaga))
}
