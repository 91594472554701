// @flow
import * as React from 'react';
import { Row, Col, Button, Icon } from 'antd';
import moment from 'moment';
import ReadMore from '@crossfield/react-read-more';
import { Template } from '../Template';
import { FundIndexChart } from '../../atoms/Charts/FundIndexChart';
import { FundAssetChart } from '../../atoms/Charts/FundAssetChart';
import { Loader } from '../../atoms/Loader';
import { numberWithCommas } from '../../../utils';
import "./fund.css";
// import "./post.css";

export const FundTemplate = (props) => {
  
  return (
  <Template menuItem="fund" {...props}>
    <Row>
      <Col md={{ offset: 2, span: 20 }}>
        <Loader loading={props.assetsLoading} text="Resultaten worden geladen">
          <div className="well">
            {props.type === 'user-data' && (
              <Row>
                <h3>Persoonlijke resultaten</h3>
                <Col span={12}>
                  <p>Investment: {props.info.investment && `€${numberWithCommas(props.info.investment.toFixed(2))}`}</p>
                  <p>Current value: {props.info.current && `€${numberWithCommas(props.info.current.toFixed(2))}`}</p>
                </Col>

                <Col span={12}>
                  <p>Return on investment: {props.info.roi && `${numberWithCommas(props.info.roi.toFixed(2))}%`}</p>            
                  <p>Profit: {props.info.profit && `€${numberWithCommas(props.info.profit.toFixed(2))}`}</p>
                </Col>
              </Row>
            )}
            {props.type === 'fund-data' && (
              <Row>
                <h2>Fund resultaten</h2>
                <Col span={12}>
                  <p>Index: {props.info.index && `${numberWithCommas(props.info.index.toFixed(8))}`}</p>
                  <p>Score: {props.info.score && `${numberWithCommas(props.info.score)}`}</p>
                </Col>

                <Col span={12}>
                  <p>Total BTC: {props.info.totalBTC && `${numberWithCommas(props.info.totalBTC.toFixed(8))} BTC`}</p>            
                  <p>Total EUR: {props.info.totalEUR && `€${numberWithCommas(props.info.totalEUR.toFixed(2))}`}</p>
                </Col>
              </Row>
            )}
          </div>
          <div className="space-60" />
        </Loader>
        <FundIndexChart
          title={props.type ==='fund-data' ? 'Index Performance' : 'Return On Investment'}
          subtitle={props.type ==='fund-data' ? 'Historical Portfolio Index' : 'Historical ROI Chart'}
          index={props.index}
          loading={props.indexLoading}
          requestIndexPeriod={props.requestIndexPeriod}
          investment={props.info.investment}
        />
        <div className="space-60" />
      </Col>
    </Row>
    { props.posts && props.posts.length ? (
      <Row>
        <Col md={{ offset: 2, span: 20 }}>
          <h3>Nieuwsbericht</h3>
          <Row>
            { props.posts.map(post => (
              <Col className="post" key={post.id}>
                <h4>{post.title}</h4>
                
                <ReadMore
                  initialHeight={360}
                  readMore={props => (
                    <div className="text-center">
                      <Button onClick={props.onClick} type="link">
                        {props.open ? 'Verberg het volledige bericht' : 'Lees het volledige bericht'}
                        <Icon
                          type={props.open ? "caret-up" : "caret-down"}
                        />
                      </Button>
                    </div>
                  )}
                >
                  <div className="post-body" dangerouslySetInnerHTML={{__html: post.body }} />
                  <p><small>Door {post.first_name} {post.last_name} op {moment(post.date).format('DD/MM/YYYY HH:mm')}</small></p>
                </ReadMore>
              </Col>
              ))}
          </Row>
          <div className="space-60" />
        </Col>
      </Row>
    ) : null}
    <Row>
      {(props.assetsLoading || props.assets) &&
        <Col md={{ offset: 2, span: 10 }}>
          <FundAssetChart assets={props.assets} loading={props.assetsLoading}/>
        </Col>
      }
      <Col md={{ offset: 2, span: 8 }}>
        <h3 style={{ marginTop: '3em'}}>Welkom bij New Florence</h3>
        <p>Dit dashboard biedt een overzicht van uw investering bij New Florence.
        De grafiek bovenaan laat de performantie over de tijd zien van uw investering.
        Links ziet u een schijfdiagram dat de verdeling van de portfolio laat zien. Een portfolio zoals de onze bevat verschillende coins of tokens die allemaal een investering voorstellen in de onderliggende technologie van die projecten. De samenstelling van deze portfolio kan op elk moment wijzigen wanneer beslist wordt om posities te vergroten of te verkleinen of wanneer in een nieuw project wordt geïnvesteerd. 
        </p>
        <div className="space-60" />
      </Col>
    </Row>
    <Row>
      <Col md={{ offset: 2, span: 10 }} className="disclaimer">
        <h3 style={{ marginTop: '3em'}}>Disclaimer</h3>
        <p>Deze statistieken updaten elke dag eenmaal. We plannen om deze frequentie nog te verhogen waardoor geïnteresseerden nog nauwlettender hun resultaten kunnen opvolgen.</p>
        <p>De cijfers die u hier ziet zijn steeds onder voorbehoud. Aangezien deze software nog relatief nieuw is kunnen mogelijke fouten nog steeds optreden. De afgebeelde cijfers geven een idee van het bruto resultaat voor aftrek van beheerskosten en schatplichtige belastingen. Hou er dus rekening mee dat uw effectieve winst lager ligt.</p>
        <p>Uitdrukkelijk verklaren we dat dit dashboard niet geïnterpreteerd mag worden als beleggingsadvies. Het toont enkel een overzicht van de interne eigendommen die New Florence cvba als bedrijf bezit. Resultaten uit het verleden zijn geen indicatie voor resultaten in de toekomst.</p>
      </Col>
      <Col md={{ offset: 2, span: 8 }}>
        <div className="space-50" />
        <img style={{width: '80%'}} src="https://bitcoinist.com/wp-content/uploads/2018/06/shutterstock_1018654609.jpg" alt="" />
      </Col>
    </Row>
    <div className="space-80" />
  </Template>
  )
};