// @flow
import * as React from 'react';
import { Radio } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { Loader } from '../Loader';

type TProps = {
    index: Array < Array < string >> ,
    loading: boolean,
    title: string,
    subtitle: string,
}

type TState = {
    period: string,
}

export class FundIndexChart extends React.Component < TProps, TState > {
    constructor(props) {
        super(props);
        this.state = {
            period: 'ALL',
            chart: {
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
            },
            title: {
                text: 'Index Performance'
            },
            subtitle: {
                text: 'Historical Portfolio Index'
            },
            colors: ['#1890ff'],
            credits: {
                enabled: false,
            },
            xAxis: {
                type: 'datetime',
                gridLineWidth: 1,
            },
            yAxis: {
                gridLineWidth: 1
            },
            series: [{
                name: 'ROI',
                type: 'spline'
            }]
        };
    }
    changePeriod(period) {
        this.props.requestIndexPeriod(period);
        this.setState({
            period,
        });
    }
    render() {
        const period = this.state.period;
        const investment = this.props.investment;
        const options = {
                ...this.state,
                title: { text: this.props.title },
                subtitle: { text: this.props.subtitle },
                xAxis: {
                    ...this.state.xAxis,
                    labels: {
                        formatter: function() {
                            if (period === 'ALL') {
                                return moment(this.value).format('MMM');
                            } else {
                                return moment(this.value).format('DD/MM');
                            }
                        }
                    },
                },
                tooltip: {
                    formatter: function() {
                        const date = moment(this.x).format('DD/MM/YYYY');
                        if (investment) {
                            return `
                                <p>${date}</p><br>
                                <p>ROI: ${((this.y)*100).toFixed(2)}%</p><br>
                                <p>Value: €${((this.y+1)*investment).toFixed(2)}</p>`;
                        }
                        return `
                            <p>${date}</p><br>
                            <p>ROI: ${((this.y)*100).toFixed(2)}%</p><br>`;
                    }
                },
            };
        options.series[0].data = this.props.index.map(x => [new Date(x[0]).getTime(), x[1]]);
        const emptyResults = this.props.index.length === 0;
        
        return (
            <div>
                <Radio.Group
                    className="period-selector"
                    value={this.state.period}
                    onChange={(e) => this.changePeriod(e.target.value)}
                    disabled={this.props.loading || emptyResults}
                >
                    <Radio.Button value="ALL">ALL</Radio.Button>
                    <Radio.Button value="MONTH">30d</Radio.Button>
                    <Radio.Button value="WEEK">7d</Radio.Button>
                </Radio.Group>
                <Loader loading={this.props.loading}>
                {
                    !emptyResults ?
                    (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                        />
                    ) :
                    <p>Er is onvoldoende informatie beschikbaar voor deze grafiek.</p>
                }
                </Loader>
                </div>
        )
    }
}