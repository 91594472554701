// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState, TBaseProps } from '../../types';
import { ExchangeEditTemplate } from '../../templates/Assets';
import { saveExchangeFormAction } from '../../../store/actions';

type TProps = TBaseProps & {};

const ExchangeEdit = (props: TProps) => (
  <ExchangeEditTemplate {...props}/>
);

const mapStateToProps = (state: TGlobalState, props: TProps) => ({
  exchange: state.fund.exchangeList
    .find(exchange => exchange.id.toString() === props.match.params.exchangeId),
});
export const ExchangeEditPage = connect(mapStateToProps, {
  saveExchangeFormAction,
})(ExchangeEdit);