// @flow
import * as React from 'react';
import { Row, Col, Button, Icon, Menu, Dropdown } from 'antd';
import { createPath } from '../../../utils';
import { Template } from '../Template';
import { SITE_PATHS } from '../../../config/api.constants';
import { browserHistory } from '../../../store';
import { Loader } from '../../atoms/Loader';
import "./post.css";
import moment from 'moment';

const menu = (props, row) => (
  <Menu>
    <Menu.Item key="6" onClick={() => browserHistory.push(createPath(SITE_PATHS.FUND+SITE_PATHS.POSTS+SITE_PATHS.POST_EDIT, { id: row.fund_id, postId: row.id }))}>
      <Icon type="edit" theme="outlined" /> Edit
    </Menu.Item>
    <Menu.Item key="7" onClick={() => window.confirm("Are you sure you want to delete this post?") && props.removePostAction(row)}>
      <Icon type="delete" theme="outlined" /> Delete
    </Menu.Item>
  </Menu>
);

export const PostsTemplate = (props) => {
  const renderPost = (post) => (
    <div className="post">
      {props.isAdmin && (
        <div className="post-menu">        
          <Dropdown overlay={menu(props, post)} trigger={['click']} placement="bottomRight">
            <Button className="ant-dropdown-link" href="#">
              <Icon type="setting" theme="outlined" />
            </Button>
          </Dropdown>
        </div>
      )}

      <h2>{post.title}</h2>
      <span dangerouslySetInnerHTML={{__html: post.body }} />
      <p><small>Door {post.first_name} {post.last_name} op {moment(post.date).format('DD/MM/YYYY HH:mm')}</small></p>
    </div>
  );
  const fundId = parseInt(props.match.params.id, 10);

  return (
    <Template menuItem="funds" {...props}>
      <h1>Latest News</h1>
      <Loader loading={props.loading}>
        <Row gutter={16}>
          {props.posts.map((post) => <Col key={post.id} xs={24} md={12}>{renderPost(post)}</Col>)}
          {!props.posts.length && (<Col><p>Er zijn nog geen nieuwsberichten voor dit fonds. </p></Col>)}
        </Row>
      </Loader>
      <div className="space-10" />
      {props.isAdmin && (<Button type="primary" onClick={() => browserHistory.push(createPath(SITE_PATHS.FUND+SITE_PATHS.POSTS+SITE_PATHS.NEW, { id: fundId }))}>Add new post <Icon type="plus"/></Button>)}
    </Template>
  )};