// @flow
import * as React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { SITE_PATHS, USER_ROLES } from '../../../config/api.constants';

export const HorizontalMenu = (props) => (
  <Menu
    mode="horizontal"
    defaultSelectedKeys={[props.menuItem]}
    style={{ lineHeight: '64px', float: 'left' }}
  >
    {/*<Menu.Item key="home">
      <Link href to={SITE_PATHS.HOME}>
        Home
      </Link>
    </Menu.Item>*/}
    {props.user && props.user.role === USER_ROLES.USER && (
      <Menu.Item key="fund">
        <Link href to={SITE_PATHS.FUND.replace(':id', 1)}>
          Dashboard
        </Link>
      </Menu.Item>
    )}
    {props.user && props.user.role === USER_ROLES.ADMIN && (
      <Menu.Item key="funds">
        <Link href to={SITE_PATHS.FUNDS}>
          Funds
        </Link>
      </Menu.Item>
    )}
   {(!props.user || !props.user.id) && <Menu.Item key="login">
      <Link href to={SITE_PATHS.LOGIN}>
        Login
      </Link>
    </Menu.Item>}
  </Menu>
);