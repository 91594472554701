// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { UserRoute } from './UserRoute';
import { USER_ROLES } from '../../../config/api.constants';
import { logoutAction } from '../../../store/actions';

const Admin = ({ component: Component, ...rest }) => (
  <UserRoute
    {...rest}
    component={(props) => props.user && props.user.role === USER_ROLES.ADMIN
      ? <Component {...rest} {...props}/>
      : <p>You are not allowed here.</p>
    }
  />
);

const mapStateToProps = (state: TGlobalState) => ({
  user: state.user.profile,
});
export const AdminRoute = connect(mapStateToProps, {
  logoutAction,
})(Admin);