import * as React from 'react';
import { Form, Input, Button, Icon  } from 'antd';
import { Link } from 'react-router-dom';
import { SITE_PATHS } from '../../../config/api.constants';

const FormItem = Form.Item;

class Login extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = { password: '' };
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const handleSubmit = e => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.props.loginAction(values.email, values.password);
        }
      });
    };
    return (
      <Form onSubmit={handleSubmit} className="login-form" autoComplete="true">
          <FormItem>
            {getFieldDecorator('email', {
              rules: [{ message: 'Voer uw e-mailadres in', required: true }],
            })(
              <Input
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Email"
              />,
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('password', {
              rules: [{ message: 'Voer uw wachtwoord in', required: true }],
            })(
              <Input
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Password"
              />,
            )}
          </FormItem>
          {this.props.validation && <FormItem className="validation-message">{this.props.validation}</FormItem>}
          <FormItem>
            <Button type="primary" htmlType="submit" className="login-form-button">
              Log in
            </Button>
          </FormItem>
          <p>
            <Link href to={SITE_PATHS.FORGOT_PASSWORD}>Paswoord vergeten</Link>
          </p>
        </Form>
        );
      }
    }
    export const LoginForm = Form.create()(Login);