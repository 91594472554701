// @flow
import { put, call, takeLatest } from 'redux-saga/effects';
import type { TBaseAction, TIndexFilter } from '../../types';
import { getIndex, getAssets } from '../../api';
import { genericErrorHandler } from '../../utils';
import { receivedIndexAction, receivedAssetsAction, receivedFundInfoAction, receivedFundInfoTypeAction } from '../actions';

export function* indexSaga(action: TBaseAction<TIndexFilter>): Generator<*, *, *> {
  try {
    const response = yield call(getIndex, action.payload);
    yield put(receivedIndexAction(response.data));
  } catch (e) {
    console.log(e);
  }
}

export function* assetsSaga(action: TBaseAction<number>): Generator<*, *, *> {
  try {
    const response = yield call(getAssets, action.payload);
    yield put(receivedAssetsAction(response.data.assets));
    yield put(receivedFundInfoAction(response.data.info));
    console.log(response.data);
    yield put(receivedFundInfoTypeAction(response.data.type));
  } catch (e) {
    console.log(e);
  }
}

// SAGAS
export function* ChartsSaga(): Generator<*, *, *> {
  yield takeLatest('CHART_ACTIONS.REQUEST_INDEX', genericErrorHandler(indexSaga));
  yield takeLatest('CHART_ACTIONS.REQUEST_ASSETS', genericErrorHandler(assetsSaga));
}
