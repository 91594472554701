// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState, TBaseProps } from '../../types';
import { ColdStorageEditTemplate } from '../../templates/Assets';
import { saveColdStorageFormAction } from '../../../store/actions';

type TProps = TBaseProps & {};

const ColdStorageEdit = (props: TProps) => (
  <ColdStorageEditTemplate {...props}/>
);

const mapStateToProps = (state: TGlobalState, props: TProps) => ({
  storage: state.fund.coldStorageList
    .find(storage => storage.id.toString() === props.match.params.storageId),
  exchangeList: state.fund.exchangeList,
});
export const ColdStorageEditPage = connect(mapStateToProps, {
  saveColdStorageFormAction,
})(ColdStorageEdit);