// @flow
import type { TIndexChart, TIndexFilter, TBaseAction } from '../../types';

export const requestIndexAction = (indexFilter: TIndexFilter): TBaseAction<TIndexFilter> => ({
  payload: indexFilter,
  type: 'CHART_ACTIONS.REQUEST_INDEX',
});

export const receivedIndexAction = (indexData: TIndexChart): TBaseAction<TIndexChart> => ({
  payload: indexData,
  type: 'CHART_ACTIONS.RECEIVED_INDEX',
});

export const requestAssetsAction = (payload: {fund_id: number, user_id: number}): TBaseAction<number> => ({
  payload,
  type: 'CHART_ACTIONS.REQUEST_ASSETS',
});

export const receivedFundInfoTypeAction = (type: string) : TBAseAction<string> => ({
  payload: type,
  type: 'CHART_ACTIONS.RECEIVED_FUND_INFO_TYPE',
});

export const receivedAssetsAction = (indexData: TIndexChart): TBaseAction<TIndexChart> => ({
  payload: indexData,
  type: 'CHART_ACTIONS.RECEIVED_ASSETS',
});
