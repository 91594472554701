// @flow
import { appApi } from '../config/api.config';
import { API_PATHS, BASE_URL } from '../config/api.constants';
import type { TPost } from '../types';
import { createPath } from '../utils';

// FUND
export const getPosts = (fundId: number) => appApi.get(createPath(BASE_URL + API_PATHS.USER + API_PATHS.FUND + API_PATHS.POSTS, { fundId }));
export const getPost = (payload:{fundId: number, postId: number}) => appApi.get(createPath(BASE_URL + API_PATHS.USER + API_PATHS.FUND + API_PATHS.POSTS+ API_PATHS.POST, { fundId: payload.fundId, postId: payload.postId }));
export const getLastPost = (fundId: number) => appApi.get(createPath(BASE_URL + API_PATHS.USER + API_PATHS.FUND + API_PATHS.POSTS+ API_PATHS.LAST, { fundId }));
export const createPost = (post: TPost) => appApi.post(createPath(BASE_URL + API_PATHS.ADMIN + API_PATHS.FUND + API_PATHS.POSTS, { fundId: post.fund_id }), post);
export const updatePost = (post: TPost) => appApi.put(createPath(BASE_URL + API_PATHS.ADMIN + API_PATHS.FUND + API_PATHS.POSTS + API_PATHS.POST, { fundId: post.fund_id, postId: post.id }), post);
export const removePost = (post: TPost) => appApi.delete(createPath(BASE_URL + API_PATHS.ADMIN + API_PATHS.FUND + API_PATHS.POSTS + API_PATHS.POST, { fundId: post.fund_id, postId: post.id }));
