export * from './error.utils';
export * from './routing.utils';

export const truncate = (text, chars) => {
  if (text.length > chars)
     return text.substring(0,chars)+'...';
  else
     return text;
};

export const numberWithCommas = (x) => {
   var parts = x.toString().split(".");
   parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
   return parts.join(".");
};