// @flow
import * as React from 'react';
import { Template } from '../Template';
import { ShareholderForm } from '../../atoms/Forms';

export const ShareholderEditTemplate = (props) =>{
  return (
    <Template menuItem="funds" {...props}>
      <h1>Bewerk Shareholders</h1>
      <ShareholderForm {...props} />
    </Template>
);}