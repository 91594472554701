// @flow
import { put, call, takeLatest } from 'redux-saga/effects';
import type { TBaseAction, TIndexFilter } from '../../types';
import { getEmails, getLogs } from '../../api';
import { genericErrorHandler } from '../../utils';
import { receivedEmailsAction, receivedLogsAction } from '../actions';

export function* getEmailsSaga(action: TBaseAction<TIndexFilter>): Generator<*, *, *> {
  try {
    const response = yield call(getEmails, action.payload);
    yield put(receivedEmailsAction(response.data));
  } catch (e) {
    console.log(e);
  }
}
export function* getLogsSaga(action: TBaseAction<TIndexFilter>): Generator<*, *, *> {
  try {
    const response = yield call(getLogs, action.payload);
    yield put(receivedLogsAction(response.data));
  } catch (e) {
    console.log(e);
  }
}


// SAGAS
export function* LogsSaga(): Generator<*, *, *> {
  yield takeLatest('LOGS_ACTIONS.REQUEST_LOGS', genericErrorHandler(getLogsSaga));
  yield takeLatest('LOGS_ACTIONS.REQUEST_EMAILS', genericErrorHandler(getEmailsSaga));
}
