// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState, TBaseProps } from '../../types';
import { LoginTemplate } from '../templates';
import { loginAction, receivedUserValidationAction } from '../../store/actions';

type TProps = TBaseProps & {
  loginAction: typeof loginAction,
  receivedUserValidationAction: typeof receivedUserValidationAction,
};

class Login extends React.Component<TProps, {}> {
  constructor(props) {
    super(props);
    props.receivedUserValidationAction(null);
  }

  render() {
    return <LoginTemplate {...this.props} />;
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  validation: state.user.validation,
});
export const LoginPage = connect(mapStateToProps, {
  loginAction,
  receivedUserValidationAction,
})(Login);
