// @flow
import React from 'react';
import { Route, Switch, withRouter } from 'react-router';
import {
  HomePage,
  LoginPage,
  ActivatePage,
  ForgotPasswordPage,
  ResetPasswordPage,
  FundPage,
  FundsPage,
  FundCreatePage,
  FundEditPage,
  AssetsPage,
  ExchangeCreatePage,
  ExchangeEditPage,
  ColdStorageCreatePage,
  ColdStorageEditPage,
  ShareholdersPage,
  ShareholderCreatePage,
  ShareholderEditPage,
  PostsPage,
  PostCreatePage,
  PostEditPage,
  EmailsPage,
  EmailCreatePage,
  InventoryPage,
  LogsPage,
  NotFoundPage,
} from '../components/pages';
import { SITE_PATHS } from './api.constants';
import { AdminRoute, UserRoute } from '../components/atoms/Routes';

const Routes = () => (
  <Switch>
    <Route exact path={SITE_PATHS.LOGIN} component={LoginPage} />
    <Route exact path={SITE_PATHS.HOME} component={HomePage} />
    <Route exact path={SITE_PATHS.ACTIVATE} component={ActivatePage} />
    <Route exact path={SITE_PATHS.FORGOT_PASSWORD} component={ForgotPasswordPage} />
    <Route exact path={SITE_PATHS.RESET_PASSWORD} component={ResetPasswordPage} />
    
    {/* FUND */}
    <AdminRoute exact path={SITE_PATHS.FUNDS} component={FundsPage} />
    <AdminRoute exact path={SITE_PATHS.FUND+SITE_PATHS.EMAILS} component={EmailsPage} />
    <AdminRoute exact path={SITE_PATHS.FUND+SITE_PATHS.EMAILS+SITE_PATHS.NEW} component={EmailCreatePage} />
    <AdminRoute exact path={SITE_PATHS.FUND+SITE_PATHS.LOGS} component={LogsPage} />

    <UserRoute exact path={SITE_PATHS.FUND+SITE_PATHS.POSTS} component={PostsPage} />
    <AdminRoute exact path={SITE_PATHS.FUND+SITE_PATHS.POSTS+SITE_PATHS.NEW} component={PostCreatePage} />
    <AdminRoute exact path={SITE_PATHS.FUND+SITE_PATHS.POSTS+SITE_PATHS.POST_EDIT} component={PostEditPage} />
    
    <AdminRoute exact path={SITE_PATHS.FUNDS+SITE_PATHS.NEW} component={FundCreatePage} />
    <AdminRoute exact path={SITE_PATHS.FUND+SITE_PATHS.EDIT} component={FundEditPage} />
    <UserRoute exact path={SITE_PATHS.FUND} component={FundPage} />

    {/* SHAREHOLDERS */}
    <AdminRoute exact path={SITE_PATHS.FUND+SITE_PATHS.SHAREHOLDERS} component={ShareholdersPage} />
    <AdminRoute exact path={SITE_PATHS.FUND+SITE_PATHS.SHAREHOLDERS+SITE_PATHS.NEW} component={ShareholderCreatePage} />
    <AdminRoute exact path={SITE_PATHS.FUND+SITE_PATHS.SHAREHOLDERS+SITE_PATHS.SHAREHOLDER_EDIT} component={ShareholderEditPage} />

    {/* ASSETS */}
    <AdminRoute exact path={SITE_PATHS.FUND+SITE_PATHS.ASSETS} component={AssetsPage} />
    <AdminRoute exact path={SITE_PATHS.FUND+SITE_PATHS.ASSETS+SITE_PATHS.EXCHANGE+SITE_PATHS.NEW} component={ExchangeCreatePage} />
    <AdminRoute exact path={SITE_PATHS.FUND+SITE_PATHS.ASSETS+SITE_PATHS.EXCHANGE+SITE_PATHS.EXCHANGE_EDIT} component={ExchangeEditPage} />
    <AdminRoute exact path={SITE_PATHS.FUND+SITE_PATHS.ASSETS+SITE_PATHS.STORAGE+SITE_PATHS.NEW} component={ColdStorageCreatePage} />
    <AdminRoute exact path={SITE_PATHS.FUND+SITE_PATHS.ASSETS+SITE_PATHS.STORAGE+SITE_PATHS.STORAGE_EDIT} component={ColdStorageEditPage} />
    
    <AdminRoute exact path={SITE_PATHS.FUND+SITE_PATHS.INVENTORY} component={InventoryPage} />
    <UserRoute path={'*'} component={NotFoundPage} />
  </Switch>
);

export default withRouter(Routes);
