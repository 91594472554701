// @flow
import * as React from 'react';
import { Template } from '../Template';

export const NotFoundTemplate = (props) => (
  <Template menuItem="home" {...props}>
    <div>
      <h1 style={{ margin: "20px 0"}}>404 Not found</h1>
      <p style={{ margin: "10px auto 150px"}}>This page could not be found.</p>
    </div>
  </Template>
  );