// @flow
import { combineReducers } from 'redux';
import { objectReducer, valueReducer } from './generic';

const reducer = combineReducers({
  profile: objectReducer('USER_ACTIONS.RECEIVED_USER'),
  validation: valueReducer(null, 'USER_ACTIONS.RECEIVED_LOGIN_VALIDATION'),
  token: objectReducer('USER_ACTIONS.RECEIVED_TOKEN'),
});

export default reducer;
