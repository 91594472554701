export * from './base.types';
export * from './user.types';
export * from './fund.types';
export * from './charts.types';
export * from './posts.types';

// STATES
export type TGlobalState = {
  user: TUserState,
  fund: TFundState,
  charts: TChartsState,
  posts: TPostsState,
};