// @flow
/* eslint sort-keys: 0 */
export const BASE_URL = process.env.REACT_APP_API_URL;

export const API_PATHS = {
  USER: '/user',
  ADMIN: '/admin',
  LOGIN: '/login',
  CHARTS: '/charts/:fundId',
  INDEX: '/index',
  INVITE: '/invite/:userId',
  ASSETS: '/assets',
  INVENTORY: '/inventory',
  FUNDS: '/funds',
  FUND: '/:fundId',
  EXCHANGES: '/exchange',
  EXCHANGE: '/:exchangeId',
  STORAGES: '/storage',
  STORAGE: '/:storageId',
  SHAREHOLDERS: '/shareholder',
  SHAREHOLDER: '/:shareholderId',
  CODE: '/code/:code',
  ACTIVATE: '/activate/:code',
  FORGOT: '/forgot-password',
  RESET: '/reset-password/:code',
  LOGS: '/logs',
  EMAILS: '/emails',
  EMAIL: '/email',
  POSTS: '/post',
  POST: '/:postId',
  LAST: '/last',
};

export const SITE_PATHS = {
  HOME: '/',
  FUND: '/f/:id',
  LOGIN: '/login',
  ACTIVATE: '/activate/:activation_code',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:activation_code',

  // ADMIN
  FUNDS: '/funds',
  SHAREHOLDERS: '/shareholders',
  ASSETS: '/assets',
  INVENTORY: '/inventory',
  EXCHANGE: '/exchange',
  STORAGE: '/storage',
  NEW: '/new',
  EDIT: '/edit',
  SHAREHOLDER_EDIT: '/:shareholderId/edit',
  EXCHANGE_EDIT: '/:exchangeId/edit',
  STORAGE_EDIT: '/:storageId/edit',
  LOGS: '/logs',
  EMAILS: '/emails',
  POSTS: '/posts',
  POST_EDIT: '/:postId/edit'
};

export const API_STATUS_CODE = {
  OK: 200,
  SUCCESS_NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
};

export const REQUEST_HEADERS = {
  'Content-Type': 'application/json',
};

export const USER_ROLES = {
  USER: 'user',
  ADMIN: 'admin',
};