// @flow
const initialState: boolean = false;

export const loaderReducer = (setActionType: string, unsetActionType: string) => (state: boolean = initialState, action: Object) => {
  switch (action.type) {
    case setActionType:
      return true;
    case unsetActionType:
      return false;

    default:
      return state;
  }
};
