// @flow
import * as React from 'react';
import { Template } from '../Template';
import { ColdStorageForm } from '../../atoms/Forms';

export const ColdStorageEditTemplate = (props) => (
  <Template menuItem="funds" {...props}>
    <h1>Edit Cold Storage</h1>
    <div className="well">
      The amount stored on the address should be updated manually.
    </div>
    <div className="space-25" />
    <ColdStorageForm {...props} />
  </Template>
  );