// @flow
import { TInventory } from '../../types';

export const requestInventoryAction = (fundId: number): TBaseAction<> => ({
  payload: fundId,
  type: 'FUND_ACTIONS.REQUEST_INVENTORY'
});

export const receivedInventoryAction = (inventory): TBaseAction<TInventory> => ({
  payload: inventory,
  type: 'FUND_ACTIONS.RECEIVED_INVENTORY'
});