// @flow
export type THandleErrorAction = { payload: Object, type: 'HANDLE_ERROR' };
export type THandleServerErrorAction = { payload: Object, type: 'HANDLE_SERVER_ERROR' };
export type THandleNetworkErrorAction = { payload: Object, type: 'HANDLE_NETWORK_ERROR' };
export type THandleOtherErrorAction = { payload: Object, type: 'HANDLE_OTHER_ERROR' };

export const createHandleErrorAction = (error: Object): THandleErrorAction => ({
  payload: error,
  type: 'HANDLE_ERROR',
});

export const createHandleServerErrorAction = (error: Object): THandleServerErrorAction => ({
  payload: error,
  type: 'HANDLE_SERVER_ERROR',
});

export const createHandleNetworkErrorAction = (error: Object): THandleNetworkErrorAction => ({
  payload: error,
  type: 'HANDLE_NETWORK_ERROR',
});

export const createHandleOtherErrorAction = (error: Object): THandleOtherErrorAction => ({
  payload: error,
  type: 'HANDLE_OTHER_ERROR',
});
