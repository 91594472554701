// @flow
import type { TCredentials, TUser } from '../../types';

export const loginAction = (email: string, password: string): TBaseAction<TCredentials> => ({
  payload: {
    password,
    email,
  },
  type: 'USER_ACTIONS.LOGIN',
});

export const autoLoginAction = (): TAction<> => ({
  type: 'USER_ACTIONS.AUTO_LOGIN',
});

export const logoutAction = () : TAction => ({
  type: 'USER_ACTIONS.LOGOUT',
});

export const requestUserByCodeAction = (code: string): TBaseAction<string> => ({
  payload: code,
  type: 'USER_ACTIONS.GET_USER_BY_CODE',
});

export const requestForgotPasswordAction = (email: string): TBaseAction<string> => ({
  payload: email,
  type: 'USER_ACTIONS.REQUEST_FORGOT_PASSWORD',
});

export const requestResetPasswordAction = (code: string, password: string): TBaseAction<string> => ({
  payload: {code, password},
  type: 'USER_ACTIONS.REQUEST_RESET_PASSWORD',
});

export const requestActivateUserAction = (code: string, password: string): TBaseAction<string> => ({
  payload: {code, password},
  type: 'USER_ACTIONS.ACTIVATE_USER',
});

export const receivedUserAction = (user: TUser): TBaseAction<TUser> => ({
  payload: user,
  type: 'USER_ACTIONS.RECEIVED_USER',
});

export const receivedTokenAction = (token: string): TBaseAction<string> => ({
  payload: token,
  type: 'USER_ACTIONS.RECEIVED_TOKEN',
});

export const receivedUserValidationAction = (message: string): TBaseAction<string> => ({
  payload: message,
  type: 'USER_ACTIONS.RECEIVED_LOGIN_VALIDATION',
});
