// @flow
import * as React from 'react';
import { Template } from '../Template';
import { ActivationForm } from '../../atoms/Forms';

export const ActivateTemplate = (props) => (
  <Template menuItem="activate" {...props}>
    <h1>Activeer uw account</h1>
    <p>Kies een nieuw wachtwoord en activeer uw account om toegang te krijgen tot het dashboard.</p>
    <ActivationForm {...props} />
  </Template>
  );