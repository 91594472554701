// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState, TUser, TBaseProps } from '../../../types';
import { ResetPasswordTemplate } from '../../templates';
import { requestResetPasswordAction, requestUserByCodeAction } from '../../../store/actions';

type TProps = TBaseProps & {
  user: TUser,
};

class ResetPassword extends React.Component<TProps, {}> {
  constructor(props) {
    super(props);
    props.requestUserByCodeAction(props.code);
  }

  render() {
    return <ResetPasswordTemplate {...this.props} />;
  }
}

const mapStateToProps = (state: TGlobalState, ownProps: TProps) => ({
  user: state.user.profile,
  validation: state.user.validation,
  code: ownProps.match.params.activation_code,
});
export const ResetPasswordPage = connect(mapStateToProps, {
  requestUserByCodeAction,
  requestResetPasswordAction,
})(ResetPassword);
