// @flow
import * as React from 'react';
import moment from 'moment';
import { Table } from 'antd';
import { Template } from '../Template';
import { Loader } from '../../atoms/Loader';
import "./emails.css";

export const EmailsTemplate = (props) => {
  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => moment(date).format("DD-MM-YY HH:mm Z")
      // render: (date) => moment(date).utc().fromNow()
    },
    {
      title: 'Recipient',
      dataIndex: '',
      key: 'first_name',
      render: (row) => `${row.first_name} ${row.last_name} (${row.email})`
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Delivered',
      dataIndex: 'success',
      render: (success) => success ? "Yes" : "No",
    }
  ];
  return (
    <Template menuItem="emails" {...props}>
      <h1>Emails</h1>
      <Loader loading={props.loading}>
        <Table
          dataSource={props.emails}
          columns={columns}
          size="middle"
          rowKey="id"
          rowClassName={record => record.success ? '' : 'warning'}
          expandedRowRender={record => <p dangerouslySetInnerHTML={{__html: record.body}} />}
        />
      </Loader>
    </Template>
    );
};