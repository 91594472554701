// @flow
import * as React from 'react';
import { Form, Input, Button, InputNumber, Select } from 'antd';
import { formItemLayout } from './FormElements';

const FormItem = Form.Item;
const Option = Select.Option;

class ColdStorage extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = props.storage;
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    return (
      <Form
        className="storage-form"
        onSubmit={e => {
          e.preventDefault();
          this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
              values.asset = values.asset.toLowerCase();
              if (this.props.storage) {
                this.props.saveColdStorageFormAction({
                  id: this.props.storage.id,
                  fund_id: parseInt(this.props.match.params.id),
                  ...values,
                });
              } else {
                this.props.saveColdStorageFormAction({
                  fund_id: parseInt(this.props.match.params.id),
                  ...values,
                });
              }
            }
          });
        }}
      >
        <FormItem {...formItemLayout} label="Address">
          {getFieldDecorator('address', {
            initialValue: this.state.address || this.props.storage.address,
            rules: [{
              required: true, message: "Provide a blockchain address for this asset"
            }]
          })(
            <Input />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Name (optional)">
          {getFieldDecorator('name', {
            initialValue: this.state.name,
          })(
            <Input />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Asset">
          {getFieldDecorator('asset', {
            initialValue: this.state.asset,
            rules: [{
              required: true, message: "Select an asset."
            }]
          })(
            <Input />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Amount">
          {getFieldDecorator('amount', {
            initialValue: this.state.amount,
            rules: [{
              required: true, 
              type: "number", 
              min: 0, message: "Provide a valid amount for this asset (greater than zero)"
            }]
          })(
            <InputNumber step={0.0001}/>
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Price Source">
          {getFieldDecorator('btc_value_src', {
            initialValue: this.state.btc_value_src,
            rules: [{ 
              required: getFieldValue('asset').toLowerCase() !== 'btc' && getFieldValue('asset').toLowerCase() !== 'eur', 
              message: 'Please select your price source',
            }],
          })(
            <Select style={{ minWidth: '120px' }} allowClear={true}>
              {this.props.exchangeList.map(exchange => <Option key={exchange.id} value={exchange.id}>{exchange.type}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem {...formItemLayout}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </FormItem>
      </Form>
    );
  }
}
export const ColdStorageForm = Form.create()(ColdStorage);